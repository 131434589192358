import { Entity } from "../../../core/models/entity";
import { Third } from "../../../thirds/thirds/third.model";
import { Address } from "../../addresses/addresses/address.model";
import { Document } from "../documents/document.model";

export class DocumentData extends Entity {
    public AddressId?: Address;
    public DeliveryAddressId?: Address;   

    public SaleDocumentHeaderId: Document = new Document();
    public PurchaseDocumentHeaderId: Document = new Document();
    public StockMoveDocumentHeaderId: Document = new Document();
    public ThirdId: Third = new Third();
    
    public HasPrincipal:boolean = true;
    public HasSecondary:boolean = false;
    public HasCC:boolean = false;
    public HasCCI:boolean = false;
    public UsedDeliveryAddress:boolean = false;
    
    constructor(entity?: Partial<DocumentData>) {
        super(entity);
        Object.assign(this, entity);
    }
}

import { Injectable } from '@angular/core';
import { LoadOptions } from 'devextreme/data';
import { Periodicity } from '../../../base/periodicities/periodicities/periodicity.model';
import { SaleDocumentsService } from '../../sale-documents/sale-documents/sale-documents.service';
import { SaleContract } from './sale-contract.model';
import { BehaviorSubject } from 'rxjs';
import { ApiService } from '../../../core/api.service';
import { IndexingTypesService } from '../../../base/indexing/indexing-types.service';
import { SaleContractHeaderStatus } from '../sale-contract-header-statuses/sale-contract-header-status.model';
import { ReportsService } from '../../../connectors/reports/reports.service';
import { DocumentDatasService } from '../../../base/documents/document-datas/document-datas.service';
import { DocumentsService } from '../../../base/documents/documents/documents.service';
import { Router } from '@angular/router';
import { SaleContractHeaderStatusesService } from '../sale-contract-header-statuses/sale-contract-header-statuses.service';
import { SaleContractHeaderHeaderStatus } from '../sale-contract-header-header-statuses/sale-contract-header-header-status.model';
import { ThirdsService } from '../../../thirds/thirds/thirds.service';

@Injectable({
  providedIn: 'root'
})
export class SaleContractsService extends SaleDocumentsService<SaleContract, SaleContractHeaderStatus> {
  public override url: string = 'SaleContractHeader';

  constructor(
    public saleContractHeaderStatusesService: SaleContractHeaderStatusesService,
    public thirdsService: ThirdsService,
    // public IndexingTypesService: IndexingTypesService,
    // public override apiService: ApiService,
    public override reportsService: ReportsService,
    public override documentDatasService: DocumentDatasService,
    public documentsService: DocumentsService,
    public override router: Router
  ) {
    super(reportsService, documentDatasService, documentsService, router);
  }
  public override defaultOptions: LoadOptions = {
    expand: [
      'CurrentStatusLink.StatusId',
      'ResponsibleUserId',
      'Lines($orderby=LineNo;$expand=TaxRateId($expand=NameTranslationId),UnitId,Margin,Discount,Children,SaleDocumentLineDiscountOperations,ParentId, ProductId)',
      'Statuses.StatusId',
      'DocumentDataCollection.ThirdId.ContactInformations.ContactTypeId',
      'DocumentDataCollection.ThirdId.ContactInformations.AddressId',
      'DocumentDataCollection.ThirdId.Addresses.LocalityId',
      'DocumentDataCollection.ThirdId.Addresses.PostalCodeId',
      'DocumentDataCollection.AddressId.LocalityId',
      'DocumentDataCollection.AddressId.PostalCodeId',
      'DocumentDataCollection.ThirdId.CustomerId.SaleInvoiceDocumentDelayId',
      'PeriodicityId',
      // 'IndexingTypeId',
      'ThirdId.ContactInformations.ThirdId',
      'ThirdId.ContactInformations.ContactTypeId',
      'DocumentRelations'
    ]
  };

  // Format
  // ------
  public override async format(params?: Partial<SaleContract>): Promise<SaleContract> {
    var result: any = {
      ...params,
      ThirdId: params?.ThirdId?.Id,
      ResponsibleUserId: params?.ResponsibleUserId?.Id,
      CurrentStatusLink: params?.CurrentStatusLink?.Id,
      PeriodicityId: params?.PeriodicityId?.Id,
      // PeriodicityId: params?.PeriodicityId?.Id,
      // IndexingTypeId: params?.IndexingTypeId?.Id
    };

    delete result.DocumentDataCollection;
    delete result.IntermediateThirdIdDocumentData;
    delete result.Lines;
    delete result.Statuses;
    delete result.DocumentFinances;
    // delete result.SaleQuoteHeaders;
    delete result.Payments;
    // delete result.ExtensionDate;
    // delete result.RenewalEndDate;
    delete result.DocumentRelations;
    delete result.FormattedStructuredCommunication;
    delete result.StructuredCommunication;
    delete result.Margin;
    delete result.MarginPercent;
    delete result.AmountReceived;
    delete result.AmountRemaining;
    delete result.DocumentFinances;
    delete result.SaleContractId;

    delete result.StopDate;
    delete result.DateTo;
    delete result.DateFrom;
    delete result.DocumentDelayId;
    
    delete result.ExTaxTotalWithoutGlobalDiscount;

    delete result.LateFeeInterestPercentage;
    delete result.LateFeeInterestAmount;
    delete result.DaysOverdue;
    delete result.TotalWithLateFee;
    delete result.ReminderCount;
    delete result.FixedLateFeeAmount;
    
    delete result.ExTaxTotalWithoutGlobalDiscount;

    

    //delete result.LastIndexingDate;

    // if (result.StopDate) {
    //   let stopDate: any = result.StopDate;
    //   if (!stopDate || (typeof (stopDate) != 'object' && stopDate.includes("0000")) || stopDate.getFullYear().toString() == "1" || stopDate.getFullYear().toString() == "0") {
    //     delete result.StopDate;
    //   }
    // }

    if (result.CurrentPeriodStartDate) {
      let currentPeriodStartDate: Date = new Date(result.CurrentPeriodStartDate);
      if (!currentPeriodStartDate || currentPeriodStartDate.getFullYear().toString() == "1" || currentPeriodStartDate.getFullYear().toString() == "0") {
        delete result.CurrentPeriodStartDate;
      }
    }

    if (result.CurrentPeriodEndDate) {
      let currentPeriodEndDate: any = new Date(result.CurrentPeriodEndDate);
      if (!currentPeriodEndDate || currentPeriodEndDate.getFullYear().toString() == "1" || currentPeriodEndDate.getFullYear().toString() == "0") {
        delete result.CurrentPeriodEndDate;
      }
    }

    if (result.GenerateDocumentNextDate) {
      let generateDocumentNextDate: any = new Date(result.GenerateDocumentNextDate);
      if (!generateDocumentNextDate || generateDocumentNextDate.getFullYear().toString() == "1" || generateDocumentNextDate.getFullYear().toString() == "0") {
        delete result.GenerateDocumentNextDate;
      }
    }

    return result;
  }

  public override async getInstance(contract?: Partial<SaleContract>): Promise<any> {
    var statuses = await this.saleContractHeaderStatusesService.load();

    var defaultStatus = new SaleContractHeaderHeaderStatus({
      StatusId: statuses.find((s: any) => s.Code == 'Contract.Draft')
    });

    var today = new Date();

    let element: Partial<SaleContract> = {
      Date: new Date(),
      Deadline: new Date(today.setFullYear(today.getFullYear() + 1)),
      CurrentStatusLink: defaultStatus,
      Statuses: [defaultStatus],
      DocumentRelations: [],
      StartPeriodDate: new Date(),
      PeriodicityId: new Periodicity({ Id: 3 }),
      StartPeriodDateOffset: 0,
      ...contract,

    };

    if (element.ThirdId && !element.ThirdId.CustomerId && element.ThirdId.Id) {
      element.ThirdId = await this.thirdsService.findByID(element.ThirdId.Id, { expand: ['ContactInformations.AddressId.LocalityId', 'ContactInformations.AddressId.PostalCodeId'] });
      //SaleContractDocumentDelayId
    }

    if (element.ThirdId) {
      element.DocumentDataCollection = [await this.documentDatasService.getInstance(undefined, "Sale", undefined, element.ThirdId)];
    }

    // if (element.ThirdId && element.ThirdId.CustomerId) {
    //   element.DocumentDelayId = element.ThirdId.CustomerId.SaleContractDocumentDelayId;
    // }

    // if (element.DocumentDelayId) {
    //   element.Deadline = await this.documentsService.computeDeadline(element.Date ?? new Date(), element.DocumentDelayId?.Id ?? 0);
    // }
    // element = await this.refreshResponsibleUser(element);
    // element = await this.refreshThirdDocumentDelayFromCustomer(element);

    return element;
  }


  private _contractSchedulerUpdater = new BehaviorSubject<boolean>(true);
  public contractSchedulerUpdater$ = this._contractSchedulerUpdater.asObservable();
  public updateContractScheduler(): void {
    this._contractSchedulerUpdater.next(true);
  }

  public async getStatistics(filter: any) {
    var filters = this.convertODataFiltersToString(filter);
    if (filters == "") return undefined;
    return this.apiService.sendRequest('/api/SaleContract/getStatistiques/' + filters, 'GET');
  }
}

import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DxDataGridComponent } from 'devextreme-angular';
import ArrayStore from 'devextreme/data/array_store';
import ODataStore from 'devextreme/data/odata/store';
import {
  Properties,
  RowDblClickEvent,
  ExportingEvent,
  DataGridPredefinedToolbarItem,
  ToolbarItem,
  ContextMenuPreparingEvent,
  SelectionChangedEvent,
} from 'devextreme/ui/data_grid';
import { CrudComponent } from '../helpers/crud-component/crud-component.component';
import { SyslinkColumn } from '../helpers/SyslinkColumn';
import { SyslinkDataSource } from '../helpers/SyslinkDataSource';
import { fromDotNotation } from '../helpers/tools';
import { AppInjectorService } from '../services/app-injector.service';
import { TranslationsModalComponent } from './edit-cells/translations-modal/translations-modal.component';
import { GridService } from './grid.service';
import { RRule } from 'rrule';
import { ODataService } from '../../../../../erp-app/src/app/core/services/oData.service';
import {
  ConfirmModalComponent,
  SyslinkToolbarAction,
  SyslinkToolbarActionButton,
  ToolbarService,
} from '../../public-api';
import { SyslinkDataSourceOptions } from '../helpers/SyslinkDataSourceOptions';
import { ContextMenuItemAction } from '../context-menus/context-menu-item-action';
import { TranslateService } from '@ngx-translate/core';

import { exportDataGrid } from 'devextreme/excel_exporter';
import { Workbook } from 'exceljs';
import saveAs from 'file-saver';

export type SyslinkGridOption = Properties;

@Component({
  selector: 'syslink-grid',
  templateUrl: './grid.component.html',
  styleUrls: ['./grid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GridComponent extends CrudComponent implements OnInit {
  // DataSource
  // ----------
  private _items?: any[];
  Math = Math;
  @Input()
  set items(value: any) {
    this._items = value;
    this.itemsChange.emit(this._items);

    this.store = undefined;
    this.service = undefined;

    this.initDatasource();
    this.grid?.instance.option('dataSource', this.dataSource);
  }
  get items(): any[] | undefined {
    return this._items;
  }
  @Output() public itemsChange: EventEmitter<any[]> = new EventEmitter<any[]>();

  public dataSource: SyslinkDataSource = new SyslinkDataSource({});
  @Input() public store?: ODataStore | ArrayStore;
  @Input() public service?: ODataService<any>;
  @Input() public gridName?: string;

  // View Type
  // ---------
  public defaultViewType: GridViewType = GridViewTypeCode.Default;
  public viewType: GridViewType = this.defaultViewType;
  public viewTypeStorageKey: string = this.storageKey + '-viewType';

  // Archived
  // --------
  public archivedDataSource: SyslinkDataSource = new SyslinkDataSource({});
  @Input() public archivedStore?: ODataStore | ArrayStore;
  @Input() public restoreService?: ODataService<any>;
  @Input() public isInArchivedView: boolean = false;
  @ViewChild('confirmRestore') public confirmRestore?: ConfirmModalComponent;
  public async onRestoreValidate(): Promise<void> {
    if (this.service) {
      await this.service?.restore(this.confirmRestore?.data);
    } else {
      await this.restoreService?.restore(this.confirmRestore?.data);
    }
    this.confirmRestore?.close();
    this.grid?.instance.refresh();
  }

  // Toolbar
  // -------
  @Input() public toolbarActions: SyslinkToolbarAction[] = [];
  private toolbarItems: Array<DataGridPredefinedToolbarItem | ToolbarItem> = [];

  // ContextMenu
  // -----------
  @Input() public contextMenuItems: ContextMenuItemAction[] = [];

  // Columns
  // -------
  @Input() public columns: SyslinkColumn[] = [];
  public getColumn(field: string): SyslinkColumn | undefined {
    return this.columns.find((column) => column.field == field);
  }

  public calculateCollectionFilterExpression(column: any) {
    return (filterValue: any, _selectedFilterOperation: any, _target: any) => {
      return [
        `${column.field}/any(e: contains(tolower(e/${column.data.displayKey}), tolower('${filterValue}')))`,
      ];

      // return [`Users/any(user: contains(tolower(user/Name), tolower('${filterValue}')))`];
      // return [`Users/any(user: not(contains(tolower(user/Name), tolower('${filterValue}'))))`];
      // return [`Users/any(user: startswith(tolower(user/Name), tolower('${filterValue}')))`];
      // return [`Users/any(user: endswith(tolower(user/Name), tolower('${filterValue}')))`];
      // return [`Users/any(user: tolower(user/Name) eq tolower('${filterValue}'))`];
      // return [`Users/any(user: not(tolower(user/Name) eq tolower('${filterValue}')))`];
    };
  }

  public calculateBadgeFilterExpression(filterValue: any, _selectedFilterOperation: any, _target: any) {
    // TODO use best method
    var field = (<any>this).trueText.data.contentKey;
    return [
      `${(<any>this).calculateSortValue}/any(e: contains(tolower(e/${field}), tolower('${filterValue}')))`,
    ]
  }
  public calculateBlockCellFilterExpression(filterValue: any, _selectedFilterOperation: any, _target: any) {
    var field = (<any>this).trueText.data.contentKey;
    return [
      `${(<any>this).calculateSortValue}/any(e: contains(tolower(e/${field}), tolower('${filterValue}')))`,
    ]
  }

  public calculateObjectFilterExpression(
    filterValue: any,
    _selectedFilterOperation: String | null
  ): any {
    return [(<any>this).calculateSortValue, 'contains', filterValue];
  }

  // Options
  // -------
  @Input() public options: SyslinkGridOption = {};
  @Input() public exportFileName: string = 'Export';
  @Input() public key: string = 'Id';
  @Input() public keyType: string = 'Int32';

  // Actions
  // -------
  @Input() public detailsUrl: string | 'self' = 'self';

  @Input() public canCustomize: boolean = true;
  @Input() public showPager: boolean = true;
  @Input() public showAdvancedFilters: boolean = false;
  @Input() public canViewArchived: boolean = false;
  @Input() public validateColumnPermissions: Function | undefined;

  // State Storing
  // -------------
  @Input() public override storageKey?: string;
  public stateStoringEnabled: boolean = true;

  // Events
  // ------
  @Output() doubleClick: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('grid') public grid?: DxDataGridComponent;

  // Selection
  // ---------
  @Input() selectedKeys: number[] = [];
  @Output() selectedKeysChange: EventEmitter<number[]> = new EventEmitter<
    number[]
  >();
  @Output() init: EventEmitter<any> = new EventEmitter<any>();

  @Output() refresh: EventEmitter<any> = new EventEmitter<any>();

  // FilterValue
  // -----------
  @Output() onFilterValueChange: EventEmitter<any[]> = new EventEmitter<
    any[]
  >();

  constructor(
    private gridService: GridService,
    private toolbarService: ToolbarService,
    private activatedRoute: ActivatedRoute,
    private translateService: TranslateService
  ) {
    super();
    this.stateStoringEnabled = AppInjectorService.config.useStateStoring;
    this.isInArchivedView = this.router.url.split('/').pop() === 'archives';
  }

  public override ngOnInit(): void {
    super.ngOnInit();
    this.initDatasource();
    if (this.archivedStore) this.initArchivedDatasource();
    this.options.dataSource = this.isInArchivedView
      ? this.archivedDataSource
      : this.dataSource;
    this.initOptions();
  }

  override ngAfterViewInit(): void {
    super.ngAfterViewInit();
    this.updateOptions(this.options);
  }

  public onInitialize(e: any) {
    this.init.emit(this);
  }

  private initDatasource() {
    if (!this.store) {
      this.store = new ArrayStore({
        key: this.key,
        data: this.items || [],
      });
    }

    let dataSourceOptions: Partial<SyslinkDataSourceOptions> = {};
    if (this.service) {
      dataSourceOptions = {
        ...(<Partial<SyslinkDataSourceOptions>>this.service.defaultOptions),
        store: this.service.store,
        filter: this.filter ? this.filter : this.service.defaultOptions.filter,
        expand: this.expand ? this.expand : this.service.defaultOptions.expand,
        select: this.select, // ? this.select : this.service.defaultOptions.select,
      };
    } else {
      dataSourceOptions = {
        store: this.store,
        filter: this.filter,
        expand: this.expand,
        //    select: this.select,
      };
    }

    this.dataSource = new SyslinkDataSource(dataSourceOptions);
  }

  private initArchivedDatasource() {
    this.archivedStore = this.items
      ? new ArrayStore({
        key: this.key,
        data: this.items ?? [],
      })
      : this.archivedStore;

    let dataSourceOptions: Partial<SyslinkDataSourceOptions> = {};
    if (this.service) {
      dataSourceOptions = {
        ...(<Partial<SyslinkDataSourceOptions>>this.service.defaultOptions),
        store: this.service.archivedStore,
        filter: this.filter ? this.filter : this.service.defaultOptions.filter,
        expand: this.expand ? this.expand : this.service.defaultOptions.expand,
        // select: this.select ? this.select : this.service.defaultOptions.select,
      };
    } else {
      dataSourceOptions = {
        store: this.archivedStore,
        filter: this.filter,
        expand: this.expand,
        // select: this.select,
      };
    }

    this.archivedDataSource = new SyslinkDataSource(dataSourceOptions);
  }

  private initOptions() {
    this.options = this.gridService.getConfiguration(this.options, this.key);

    this.initToolbar();
    this.initEditingOptions();

    if (!this.canFilter && this.options.filterPanel && this.options.filterRow) {
      this.options.filterPanel.visible = false;
      this.options.filterRow.visible = false;
    }
    if (!this.showPager && this.options.pager) {
      this.options.pager.visible = false;
    }
  }

  private initToolbar() {
    if (!this.options.toolbar) return;

    this.toolbarItems = [];

    // BEFORE LOCATION
    // ---------------
    // Custom Add
    // ----------
    if (this.canAdd && this.add.observed) {
      const customAddAction: SyslinkToolbarAction =
        new SyslinkToolbarActionButton({
          code: 'add',
          icon: 'plus',
          location: 'before',
          onClick: () => {
            this.add.emit();
          },
        });

      this.toolbarItems.push(
        ToolbarService.generateToolbarItem(customAddAction)
      );
    }

    // Default Add
    // -----------
    if (this.canAdd && !this.add.observed) {
      this.toolbarItems.push({
        name: 'addRowButton',
        location: 'before',
        options: { elementAttr: { id: 'add' } },
      });
    }

    // Custom toolbarActions of before location
    // ----------------------------------------
    this.toolbarActions
      .filter((e) => e.location == 'before')
      .forEach((toolbarAction) => {
        this.toolbarItems.push(
          ToolbarService.generateToolbarItem(toolbarAction)
        );
      });
    // ------------------------------------------------------------------------------------

    // CENTER LOCATION
    // ---------------
    // Custom toolbarActions of center location
    // ----------------------------------------
    this.toolbarActions
      .filter((e) => e.location == 'center')
      .forEach((toolbarAction) => {
        this.toolbarItems.push(ToolbarService.generateToolbarItem(toolbarAction));
      });
    // ------------------------------------------------------------------------------------

    // AFTER LOCATION
    // --------------
    if (this.canSearch) {
      this.toolbarItems.push({ name: 'searchPanel' });
    }
    this.toolbarActions
      .filter((e) => e.location == 'after')
      .forEach((toolbarAction) => {
        this.toolbarItems.push(ToolbarService.generateToolbarItem(toolbarAction));
      });
    // Default Refresh
    // ---------------
    if (this.canRefresh) {
      const defaultRefreshAction: SyslinkToolbarAction =
        new SyslinkToolbarActionButton({
          code: 'refresh',
          icon: 'fa-solid fa-rotate',
          location: 'after',
          onClick: () => {
            this.refresh.observed
              ? this.refresh.emit()
              : this.grid?.instance.refresh();
          },
        });
      this.toolbarItems.push(ToolbarService.generateToolbarItem(defaultRefreshAction));
    }
    // ------------------------------------------------------------------------------------

    // MENU LOCATION
    // -------------
    if (this.canCustomize) {
      this.toolbarItems.push({
        name: 'columnChooserButton',
        locateInMenu: 'always',
        options: { elementAttr: { id: 'columnChooser' } },
      });
    }
    if (this.canExport) {
      this.toolbarItems.push({
        name: 'exportButton',
        locateInMenu: 'always',
        options: { elementAttr: { id: 'export' } },
      });
    }
    // if (this.canViewArchived) {
    //   const defaultArchivedAction: SyslinkToolbarAction = new SyslinkToolbarActionButton({
    //     code: "view-archived",
    //     icon: "clock",
    //     inMenu: 'always',
    //     onClick: () => {
    //       this.viewType = GridViewTypeCode.Archived;
    //       this.router.navigate(['archives'], { relativeTo: this.activatedRoute });
    //       this.initOptions();
    //     }
    //   });
    //   this.toolbarItems.push(ToolbarService.generateToolbarItem(defaultArchivedAction));
    // }
    this.toolbarActions
      .filter((e) => e.inMenu == 'always')
      .forEach((toolbarAction) => {
        this.toolbarItems.push(ToolbarService.generateToolbarItem(toolbarAction));
      });
    // ------------------------------------------------------------------------------------

    this.options.toolbar.items = this.toolbarItems;

    // if (this.archivedStore && !this.isInArchivedView) {
    //   this.toolbarItems.push(ToolbarService.generateArchivedSwitchButtonItem(() => {
    //     this.router.navigate(['archives'], { relativeTo: this.activatedRoute });
    //   }, this.translateService.instant('GRID.ARCHIVED.SWITCH.TO')));
    // }
    // if (this.archivedStore && this.isInArchivedView) {
    //   this.toolbarItems.push(ToolbarService.generateArchivedSwitchButtonItem(() => {
    //     this.router.navigate(['../'], { relativeTo: this.activatedRoute });
    //   }, this.translateService.instant('GRID.ARCHIVED.SWITCH.FROM')));
    // }
  }

  private initEditingOptions() {
    if (!this.options.editing) return;

    this.options.editing.allowAdding = this.canAdd;
    this.options.editing.allowUpdating = this.canUpdate;
    this.options.editing.allowDeleting = this.canDelete;
  }

  public onRowDblClick(event: RowDblClickEvent) {
    if (!this.canDblClck) return;
    if (this.doubleClick.observed) {
      this.doubleClick.emit(event.data);
    } else {
      if (!this.detailsUrl) return;

      if (this.detailsUrl === 'self') {
        this.router.navigate([event.data.Id], {
          relativeTo: this.activatedRoute,
        });
      } else {
        this.router.navigateByUrl(this.detailsUrl + '/' + event.data.Id);
      }
    }
  }

  public onExporting(event: ExportingEvent) {
    event.component.beginUpdate();
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet(
      this.translateService.instant(this.exportFileName)
    );

    exportDataGrid({
      component: event.component,
      worksheet: worksheet,
    })
      .then(async () => {
        const buffer: BlobPart = await workbook.xlsx.writeBuffer();
        saveAs(
          new Blob([buffer], { type: 'application/octet-stream' }),
          this.translateService.instant(this.exportFileName) + '.xlsx'
        );
      })
      .then(() => {
        event.component.endUpdate();
      });
  }

  // public refresh(): void {
  //   if (this.items) {
  //     this.grid?.instance.option('dataSource', this.items);
  //   } else {
  //     this.grid?.instance.refresh();
  //   }
  // }

  // FromDotNotation
  // ---------------
  public fromDotNotation(cell: any, key: string) {
    if (cell.value) {
      return fromDotNotation(cell.value, key);
    }
    return null;
  }

  // Translations
  // ------------
  @Input() public languages: any[] = [];
  @ViewChild('translationsCellModal')
  public translationsCellModal?: TranslationsModalComponent;

  public onTranslationsBtnClicked(translations: any, cell: any): void {
    if (this.translationsCellModal) {
      this.translationsCellModal.open(translations, cell);
    }
  }

  public onTranslationsModalValidated(translations: any): void {
    this.grid?.instance.option('editing.changes', [
      {
        data: { NameTranslationId: { Translations: translations } },
        key: this.translationsCellModal?.modal.data.NameTranslationId.key,
        type: 'update',
      },
    ]);

    this.grid?.instance.saveEditData();
    this.grid?.instance.refresh();
  }

  // Options
  // -------
  public updateOptions(options: Properties) {
    this.grid?.instance.option(options);
  }

  // Discount Cell
  // -------------
  public onDiscountCellUpdated(newValue: number, cell: any, isFixed: boolean) {
    cell.setValue(isFixed ? newValue : Number((newValue / 100).toFixed(4)));
  }

  public onDiscountTypeChange(type: number, cell: any, field: string) {
    cell.setValue(0);
  }

  // Object Discount Cell
  // --------------------
  public isNewObjectDiscountCell(cell: any): boolean {
    return (
      cell.value &&
      this.getColumn(cell.column.dataField)?.data &&
      this.getColumn(cell.column.dataField)?.data.discountTypeField
    );
  }

  public onObjectDiscountCellUpdated(
    newValue: any,
    cell: any,
    isFixed: boolean
  ) {
    // Fix format number
    newValue = parseFloat((newValue).toString().replace(',', '.'));
    
    cell.setValue({ ...cell.value, Value: isFixed ? newValue : Number(newValue / 100).toFixed(4) });
  }

  public getPrecisionWithKey(key:string){
    return AppInjectorService.config.getPrecision( key);
  }

  public onObjectDiscountTypeChange(type: number, cell: any, field: string) {
    cell.setValue({ ...cell.value, [field]: type ? true : false, Value: 0 });
  }

  // ContextMenu
  // -----------
  @Input() public onContextMenuPreparing(e: ContextMenuPreparingEvent) {
    if (e.target !== 'content') return;
    if (!this.contextMenuItems) return;
    if (
      this.contextMenuItems.filter(
        (items: ContextMenuItemAction) => items.visible == false
      ).length == this.contextMenuItems.length
    ) {
      return;
    }
    e.items = this.formatContextMenuItems(this.contextMenuItems, e.row?.data);
  }
  public formatContextMenuItems(
    items: ContextMenuItemAction[],
    rowData?: any
  ): ContextMenuItemAction[] {
    return items.map(
      (item) =>
        new ContextMenuItemAction({
          ...item,
          text: item.text
            ? this.translateService.instant(item.text)
            : item.text,
          rowData: rowData,
          items: item.items
            ? this.formatContextMenuItems(item.items, rowData)
            : item.items,
        })
    );
  }
  // TODO: amélioration #3382
  // // Enum Cell
  // // Only numeric enums
  // // ------------------
  // public generateItemFromEnum(enumItems: any): Array<any> {
  //   return Object.values(enumItems).filter((item:any) => isNaN(Number(item))).reduce((arr: Array<any>, item: any) => {
  //     arr.push({ name: item });
  //     return arr;
  //   }, []);
  // }

  // RRule cell
  // ----------
  public getRruleText(value: string): string {
    if (!value) return '';
    const rrule: RRule = RRule.fromString(value);

    if (!rrule) return '';

    const getText = (key: any): string => {
      return FRENCH.getTextDict[key] || key.toString();
      // return this.translateService.instant(key.toString());
    };

    return rrule.toText(getText, FRENCH);
  }

  public showColumn(column: SyslinkColumn): boolean {
    var result = true;

    // if (this.validateColumnPermissions) {
    //   result &&= this.validateColumnPermissions(column);
    // }

    result &&= column.technical == false;

    return result;
  }

  public applySaveState: number = 0;
  public onOptionChanged(e: any) {
    if (e.name === 'filterValue') {
      this.onFilterValueChange.emit(e.value);
    }
  }

  // State storing
  // -------------
  public saveState(state: any) {
    AppInjectorService.config.updateStateStoring(this.storageKey, state);
  }
  public loadState(): Promise<any> {
    const state = localStorage.getItem(this.storageKey ?? "");
    return Promise.resolve(state ? JSON.parse(state) : {});
  }

  // Selection
  // ---------
  // @Input() selectedKeys: number[] = [];
  @Input() selectedItems: any[] = [];
  @Output() selectedItemsChange: EventEmitter<any[]> = new EventEmitter<any[]>();
  // @Output() selectedKeysChange: EventEmitter<number[]> = new EventEmitter<number[]>();
  @Output() selectionChanged: EventEmitter<SelectionChangedEvent> = new EventEmitter<SelectionChangedEvent>();

  public onSelectionChanged(e: SelectionChangedEvent) {
    this.selectedItems = e.selectedRowsData;
    if (this.selectedItemsChange.observed) this.selectedItemsChange.emit(this.selectedItems);
    if (this.selectedKeysChange.observed) this.selectedKeysChange.emit(e.selectedRowKeys);
    if (this.selectionChanged.observed) this.selectionChanged.emit(e);

  }

}

/**
 * Used for RRULE  -> TODO: Load language from backend
 * dayNames / monthNames -> from backend
 * getTextDict -> can use 'translateService.instant'
 */
interface Language {
  dayNames: string[];
  monthNames: string[];
  tokens: {
    [k: string]: RegExp;
  };
  getTextDict: {
    [k: string]: string;
  };
}

const FRENCH: Language = {
  dayNames: [
    'dimanche',
    'lundi',
    'mardi',
    'mercredi',
    'jeudi',
    'vendredi',
    'samedi',
  ],
  monthNames: [
    'janvier',
    'février',
    'mars',
    'avril',
    'mai',
    'juin',
    'juillet',
    'août',
    'septembre',
    'octobre',
    'novembre',
    'décembre',
  ],
  tokens: {},
  getTextDict: {
    th: '.',
    every: 'chaque',
    days: 'jours',
    weekdays: 'jours de la semaine',
    weeks: 'semaines',
    hours: 'heures',
    minutes: 'minutes',
    months: 'mois',
    years: 'années',
    day: 'jour',
    weekday: 'jour de la semaine',
    week: 'semaine',
    hour: 'heure',
    minute: 'minute',
    month: 'mois',
    year: 'année',
    on: 'le',
    'on the': 'le',
    at: 'à',
    the: 'le',
    first: 'premier',
    second: 'deuxième',
    third: 'troisième',
    last: 'dernier',
    for: 'pour',
    time: 'fois',
    times: 'fois',
    until: "jusqu'au",
  },
};

export type GridViewType = GridViewTypeCode.Default | GridViewTypeCode.Archived;
export enum GridViewTypeCode {
  Default = 'default',
  Archived = 'archived',
}

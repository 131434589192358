import { Injectable } from '@angular/core';
import { LoadOptions } from 'devextreme/data';
import { SaleDocumentsService } from '../../sale-documents/sale-documents/sale-documents.service';
import { SaleOrder } from './sale-order.model';
import { SaleOrderHeaderStatus } from '../sale-order-header-statuses/sale-order-header-status.model';
import { SaleOrderHeaderHeaderStatus } from '../sale-order-header-header-statuses/sale-order-header-header-status.model';
import { ReportsService } from '../../../connectors/reports/reports.service';
import { DocumentDatasService } from '../../../base/documents/document-datas/document-datas.service';
import { ThirdsService } from '../../../thirds/thirds/thirds.service';
import { SaleOrderHeaderStatusesService } from '../sale-order-header-statuses/sale-order-header-statuses.service';
import { DocumentsService } from '../../../base/documents/documents/documents.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class SaleOrdersService extends SaleDocumentsService<SaleOrder, SaleOrderHeaderStatus> {
  public override url: string = 'SaleOrderHeader';
  
  constructor(
    public override reportsService: ReportsService,
    private saleOrderHeaderStatusesService: SaleOrderHeaderStatusesService,
    public override documentDatasService: DocumentDatasService,
    private thirdsService: ThirdsService,
    public documentsService: DocumentsService,
    public override router: Router
  ) {
    super(reportsService, documentDatasService,documentsService,router);
  }
  
  public override defaultOptions: LoadOptions = {
    expand: [
      'CurrentStatusLink.StatusId',
      'ThirdId.CustomerId.SaleInvoiceDocumentDelayId',
      'ResponsibleUserId',
      'Lines($orderby=LineNo;$expand=TaxRateId($expand=NameTranslationId),UnitId,Margin,Discount,Children,SaleDocumentLineDiscountOperations,ParentId,TaxId,AccountId, ProductId)',
      'Statuses.StatusId',
      'DocumentDataCollection.ThirdId.ContactInformations.ContactTypeId',
      'DocumentDataCollection.ThirdId.ContactInformations.AddressId',
      'DocumentDataCollection.ThirdId.Addresses.LocalityId',
      'DocumentDataCollection.ThirdId.Addresses.PostalCodeId',
      'DocumentDataCollection.AddressId.LocalityId',
      'DocumentDataCollection.AddressId.PostalCodeId',
      'ThirdId.ContactInformations.ThirdId',
      'ThirdId.ContactInformations.ContactTypeId',
      'DocumentRelations'
    ]
  };

  public override async getInstance(order?: Partial<SaleOrder>): Promise<any> {
    var statuses = await this.saleOrderHeaderStatusesService.load();

    var defaultStatus = new SaleOrderHeaderHeaderStatus({
      StatusId: statuses.find((s: any) => s.Code == 'Order.Draft')
    });
    let element: Partial<SaleOrder> = {
      Date: new Date(),
      CurrentStatusLink: defaultStatus,
      Statuses: [defaultStatus],
      Payments:[],
      DocumentRelations:[],
      ...order,
    };

    if (element.ThirdId && !element.ThirdId.CustomerId && element.ThirdId.Id) {
      element.ThirdId = await this.thirdsService.findByID(element.ThirdId.Id, { expand: ['ContactInformations.AddressId.LocalityId','ContactInformations.AddressId.PostalCodeId'] });
    }

    if (element.ThirdId) {
      element.DocumentDataCollection = [await this.documentDatasService.getInstance(undefined, "Sale", undefined, element.ThirdId)];
    }

    return element;
  }
}

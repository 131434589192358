import { Injectable } from '@angular/core';
import { LoadOptions } from 'devextreme/data';
import { SaleDocumentsService } from '../../sale-documents/sale-documents/sale-documents.service';
import { SaleCreditNote } from './sale-credit-note.model';
import { SaleCreditNoteHeaderStatus } from '../sale-credit-note-header-statuses/sale-credit-note-header-status.model';
import { SaleCreditNoteHeaderStatusesService } from '../sale-credit-note-header-statuses/sale-credit-note-header-statuses.service';
import { SaleCreditNoteHeaderHeaderStatus } from '../sale-credit-note-header-header-statuses/sale-credit-note-header-header-status.model';
import { ReportsService } from '../../../connectors/reports/reports.service';
import { DocumentsService } from '../../../base/documents/documents/documents.service';
import { ThirdsService } from '../../../thirds/thirds/thirds.service';
import { DocumentDatasService } from '../../../base/documents/document-datas/document-datas.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class SaleCreditNotesService extends SaleDocumentsService<SaleCreditNote, SaleCreditNoteHeaderStatus> {
  public override url: string = 'SaleCreditNoteHeader';
  
  constructor(
    public override reportsService: ReportsService,
    private saleCreditNoteHeaderStatusesService: SaleCreditNoteHeaderStatusesService,
    public override documentDatasService: DocumentDatasService,
    private thirdsService: ThirdsService,
    public documentsService: DocumentsService,
    public override router: Router
  ) {
    super(reportsService, documentDatasService,documentsService,router);
  }

  public override defaultOptions: LoadOptions = {
    expand: [
      'ThirdId.CustomerId.PaymentMethodId',
      'ThirdId.CustomerId.SaleInvoiceDocumentDelayId',
      'CurrentStatusLink.StatusId',
      'ResponsibleUserId',
      // 'DocumentDelayId',
      // 'OriginId',
      'Lines($orderby=LineNo;$expand=TaxRateId($expand=NameTranslationId),UnitId,Margin,Discount,Children,SaleDocumentLineDiscountOperations,ParentId,TaxId,AccountId, ProductId)',
      'Statuses.StatusId',
      'DocumentDataCollection.ThirdId.ContactInformations.ContactTypeId',
      'DocumentDataCollection.ThirdId.ContactInformations.AddressId',
      'DocumentDataCollection.ThirdId.Addresses.LocalityId',
      'DocumentDataCollection.ThirdId.Addresses.PostalCodeId',
      'DocumentDataCollection.AddressId.LocalityId',
      'DocumentDataCollection.AddressId.PostalCodeId',
      'Payments.PaymentMethodPrimary',
      'Payments.PaymentStateId',
      'Payments.PaymentTypeId',
      'ThirdId.ContactInformations.ThirdId',
      'ThirdId.ContactInformations.ContactTypeId',
      'DocumentRelations'
    ]
  };

  // public getListByThird(thirdId: number) {
  //   return this.sendRequest(this.url + '/' + thirdId + '/salequoteheaderbythird');
  // }
  // public getListByRelation(headerId: number, type:string) {
  //   return this.sendRequest('/api/salequoterelation/' + headerId + '/type/'+type);
  // }
  // public getVersionByHeaderId(headerId: number) {
  //   return this.sendRequest(this.url + '/' + headerId + '/listheaderid');
  // }
  // public getLineByHeaderId(headerId: number) {
  //   return this.sendRequest(this.url + '/' + headerId + '/listline');
  // }
  // public createCreditNote(header: SaleCreditNoteHeader) {
  //   return this.sendRequest(this.url + '/cloneToCreditNote', 'POST', header);
  // }
  // public createCreditNote(header: SaleCreditNoteHeader) {
  //   return this.sendRequest(this.url + '/cloneToCreditNote', 'POST', header);
  // }

  public override async getInstance(creditNote?: Partial<SaleCreditNote>): Promise<any> {
    var statuses = await this.saleCreditNoteHeaderStatusesService.load();

    var defaultStatus = new SaleCreditNoteHeaderHeaderStatus({
      StatusId: statuses.find((s: any) => s.Code == 'CreditNote.Proforma')
    });
    let element: Partial<SaleCreditNote> = {
      Date: new Date(),
      CurrentStatusLink: defaultStatus,
      Statuses: [defaultStatus],
      Payments:[],
      DocumentRelations:[],
      ...creditNote,
    };

    if (element.ThirdId && !element.ThirdId.CustomerId && element.ThirdId.Id) {
      element.ThirdId = await this.thirdsService.findByID(element.ThirdId.Id, { expand: ['ContactInformations.AddressId.LocalityId','ContactInformations.AddressId.PostalCodeId'] });
    }

    if (element.ThirdId) {
      element.DocumentDataCollection = [await this.documentDatasService.getInstance(undefined, "Sale", undefined, element.ThirdId)];
    }

    // if (element.ThirdId && element.ThirdId.CustomerId) {
    //   element.DocumentDelayId = element.ThirdId.CustomerId.SaleCreditNoteDocumentDelayId;
    // }

    // if (element.DocumentDelayId) {
    //   element.Deadline = await this.documentsService.computeDeadline(element.Date ?? new Date(), element.DocumentDelayId?.Id ?? 0);
    // }
    // element = await this.refreshResponsibleUser(element);
    // element = await this.refreshThirdDocumentDelayFromCustomer(element);

    return element;
  }
}

<syslink-toolbar
  [actions]="toolbarActions"
  class="me-3"
  [withLine]="false"
></syslink-toolbar>

<div class="row">
  <div class="col-sm-9 col-xs-12">
    <!-- Toolbar -->

    <syslink-block
      [title]="formattedTitle"
      [translateTitle]="false"
    >

      <app-document-statuses
        *ngIf="element?.Statuses"
        #documentStatuses
        [statuses]="availableStatuses"
        [documentStatuses]="element.Statuses"
        (statusChanged)="onDocumentStatusChanged($event)"
        [showConfirmMessageSaveDocument]="true"
      ></app-document-statuses>

      <app-sale-document-form
        *ngIf="element?.Date"
        [(element)]="element"
        [disabled]="!canEditDocument()"
        [documentType]="documentType"
      ></app-sale-document-form>
    </syslink-block>
    <syslink-block
      [title]="'Document'"
      class="ms-3"
    >
      <app-sale-document-content
        #saleDocumentContent
        *ngIf="authService.hasPermission(this.newBasePermissionKey + '.content.list.view')"
        [(element)]="element"
        [documentType]="documentType"
        [subModuleCode]="subModuleCode"
        [saleDocumentService]="saleDocumentService"
        [saleDocumentLinesService]="saleDocumentLinesService"
        [columns]="documentLinesColumns"
        [disabled]="!canEditDocument()"
      ></app-sale-document-content>
    </syslink-block>
  </div>

  <div class="col-sm-3 col-xs-12">
    <syslink-block
      *ngIf="authService.hasPermission(this.newBasePermissionKey + '.totals.view')"
      [title]="'Totals'"
      [titleSize]="6"
    >
      <app-document-totals
        *ngIf="element"
        [documentType]="documentType"
        [element]="element"
      ></app-document-totals>
    </syslink-block>

    <syslink-block
      *ngIf="authService.hasPermission(this.newBasePermissionKey + '.third.view')"
      [title]="'Thirds'"
      [titleSize]="6"
      class="ms-3"
    >
      <app-third-document-data-list
        *ngIf="element?.DocumentDataCollection"
        [detailsUrl]="'/thirds/customers/'"
        [thirdFilter]="thirdDocumentDataFilters"
        [(documentDataCollection)]="element.DocumentDataCollection"
        (principalThirdChange)="principalThirdChange()"
        [disabled]="!canEditDocument()"
        [showDeliveryAddress]="documentType == 'SaleOrder' ? true:false"
      ></app-third-document-data-list>
    </syslink-block>

    <syslink-block
      *ngIf="documentType == 'SaleInvoice' && element?.DaysOverdue && element.DaysOverdue > 0"
      [title]="'Overdue payment' | translate"
      [titleSize]="6"
      class="ms-3"
    >
      <div class="row mt-2 mx-1">
        <div class="col-sm-9">{{'Days overdue' | translate}} : </div>
        <div class="col-sm-3 fw-bolder text-end text-secondary">{{element.DaysOverdue}} {{ 'days' | translate }}</div>
      </div>
      <div class="row mt-2 mx-1">
        <div class="col-sm-12">
          <syslink-input-number
            [allowExponent]="false"
            [label]="'Fixed late fee'"
            [(model)]="element.FixedLateFeeAmount"
            [min]="0"
            [precision]="2"
          ></syslink-input-number>
        </div>
      </div>
      <div class="row mt-2 mx-1">
        <div class="col-sm-10">
          <syslink-input-number
            [allowExponent]="false"
            [label]="'Late fee interest percentage'"
            [(model)]="element.LateFeeInterestPercentage"
            [min]="0"
            [precision]="0"
          ></syslink-input-number>
        </div>
        <div class="col-sm-2">
          <syslink-button
            (click)="updateLateFeeAmount()"
            icon="refresh"
          ></syslink-button>
        </div>
      </div>

    </syslink-block>

    <syslink-block
      *ngIf="authService.hasPermission(this.newBasePermissionKey + '.payments.view') && documentType!='SaleOrder'"
      [title]="'payments'"
      [titleSize]="6"
      class="ms-3"
    >
      <app-payments
        *ngIf="element && element.Payments"
        [(element)]="element.Payments"
        [document]="element"
        [documentType]="documentType"
        [filterPaymentType]="''"
        [InTaxTotal]="element.InTaxTotal"
      ></app-payments>
    </syslink-block>

    <syslink-block
    *ngIf="authService.hasPermission(this.basePermissionKey + '.contract.view') && documentType=='SaleInvoice'"
    [title]="'Contract'"
    [titleSize]="6"
    class="ms-3"
  >
    <app-sale-invoice-contract-form 
      [(element)]="element"
      [disabled]="!canEditDocument()"
      (onChangeSaleContrat)="onChangeSaleContrat()"
    ></app-sale-invoice-contract-form>
  </syslink-block>

    <syslink-block
      *ngIf="authService.hasPermission(this.newBasePermissionKey + '.documents.view') && element && element.Id"
      [title]="'Documents'"
      [titleSize]="6"
      class="ms-3"
    >
      <app-document-relations
        #documentRelation
        *ngIf="element && element.DocumentRelations"
        [(elements)]="element.DocumentRelations"
        [documentId]="element.Id"
        [documentType]="documentType"
        [disabled]="!canEditDocument()"
      ></app-document-relations>
    </syslink-block>

    <syslink-block
      *ngIf="authService.hasPermission(this.newBasePermissionKey + '.other.view')"
      [title]="'Other'"
      [titleSize]="6"
      class="ms-3"
    >
      <app-sale-document-other
        *ngIf="element"
        [(element)]="element"
        [disabled]="!canEditDocument()"
      ></app-sale-document-other>
    </syslink-block>
  </div>
</div>


<syslink-confirm-modal
  #deleteConfirm
  title="Delete"
  content="DeleteConfirmMessage"
  (onValidate)="onDeleteConfirmed()"
  validateText="Confirm"
  cancelText="Cancel"
></syslink-confirm-modal>

<app-mail-modal
  *ngIf="mailModel"
  #emailModal
  [(mailModel)]="mailModel"
  [mailTemplateType]="mailTemplateType"
  [element]="element"
  [documentType]="documentType"
></app-mail-modal>

<app-document-copy-modal
  #copyModal
  (validate)="onCopyButtonClicked($event)"
></app-document-copy-modal>

<syslink-confirm-modal
  #saveConfirmModel
  title="{{'Confirm saving'|translate}}"
  (onValidate)="onConfirmeSavingBeforeEvent($event)"
  validateText="Confirm"
  cancelText="Cancel"
>
</syslink-confirm-modal>
<syslink-modal #previewModal [title]="previewId" [height]="'auto'" width="50vw" [noFooter]="true" [resize]="true">
  <iframe
      [src]="previewData"
      [id]="previewId"
      class="w-100"
      [style.height]="'68vh'"
      frameBorder="0"
  ></iframe>

  <div class="w-100 text-end">
    <div class="btn-group" role="group">
      <button class="btn btn-primary" (click)="onRegeneratePreviewButtonClicked($event)">{{ 'Regenerate' | translate}}</button>
      <a class="btn btn-primary text-white" [href]="previewData" [download]="previewId" target='_self'>{{ 'Download' | translate}}</a>
    </div>
  </div>
</syslink-modal>

import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { WorkTimeInvoicingLine } from '../../work-times/work-time-invoicing-line/work-time-invoicing-line.model';
import { SyslinkColumn } from 'projects/libraries/syslink-components/src/lib/helpers/SyslinkColumn';
import { BadgeListCellData, DurationCellData, ModalComponent, NotificationsService } from 'projects/libraries/syslink-components/src/public-api';
import { Task } from '../tasks/task.model';
import { TasksService } from '../tasks/tasks.service';
import { DXCell } from 'projects/libraries/syslink-components/src/lib/grid/cells/dxcell';
import { TagCellData } from 'projects/libraries/syslink-components/src/lib/grid/cells/tag-cell/tag-cell.component';
import { SelectionChangedEvent } from 'devextreme/ui/tree_list';

@Component({
  selector: 'app-dynamic-price-invoicing',
  templateUrl: './dynamic-price-invoicing.component.html',
  styleUrls: ['./dynamic-price-invoicing.component.scss']
})
export class DynamicPriceInvoicingComponent {
  @Input() public tasks: Task[] = [];
  @Input() public tasksToInvoice: Task[] = [];
  @Input() public invoiceId?: number;

  @Output() public onInvoiceCreated: EventEmitter<void> = new EventEmitter<void>();

  @ViewChild('workTimesModal') public workTimesModal: ModalComponent = new ModalComponent;

  public lines: WorkTimeInvoicingLine[] = [];
  public selectedItems: WorkTimeInvoicingLine[] = [];

  private defaultDurationCellData = new DurationCellData({
    getColor: this.getDurationCellColor
  });

  public columns: SyslinkColumn[] = [
    new SyslinkColumn({ field: 'Reference', label: "Reference" }),
    new SyslinkColumn({ field: 'Description', label: "Description", cellTemplate: 'html-cell', editCellTemplate: 'edit-html-cell' }),
    new SyslinkColumn({ field: 'EstimatedTime', label: "Estimated time", type: "number", cellTemplate: 'duration-cell', data: new DurationCellData({ ...this.defaultDurationCellData, getVisibility: this.getEstimatedTimeCellVisibility }) }),
    new SyslinkColumn({ field: 'WorkedTime', label: "Worked time", type: "number", cellTemplate: 'duration-cell', data: new DurationCellData({ ...this.defaultDurationCellData }) }),
    new SyslinkColumn({ field: 'BilledTime', label: "Billed time", type: "number", cellTemplate: 'duration-cell', data: new DurationCellData({ ...this.defaultDurationCellData }) }),
    new SyslinkColumn({ field: "BilledTimeState", label: "Billed", type: "string", editable: false, cellTemplate: 'tag-cell', data: this.getTagCellData() }),
    new SyslinkColumn({ field: "Users", label: "Users", cellTemplate: 'badge-list-cell', filterable: false, headerFilterable: false, sortable: false, data: new BadgeListCellData({ contentKey: 'Name' }) }),
  ];

  constructor(
    private tasksService: TasksService
  ) { }

  // Functions
  // ---------
  public async onAddWorkTimes() {
    await this.loadData();
    if (this.lines.length === 0) {
      NotificationsService.sendErrorMessage("No work time linked to selected tasks.");
      return;
    }
    // setTimeout(() => {
      this.open();
    // }, 100);
  }

  public open() {
    this.workTimesModal.open();
  }

  public close() {
    this.workTimesModal.close();
  }

  public async loadData() {
    this.lines = [];
    this.lines = (await this.tasksService.getInvoicingLines(this.tasks));
  }

  // Format Row
  // ----------
  public onRowPrepared(e: any) {
    if (e.rowType != 'data') return;
    switch (e.data.Type) {
      case 'Task':
        this.formatPostRow(e);
        break;
    }
  }

  public formatPostRow(e: any) {
    e.rowElement.classList.add('taskCell');
  }

  private getEstimatedTimeCellVisibility(cell: DXCell) {
    return !(cell.data?.Type === "WorkTime");
  }

  private getDurationCellColor(cell: DXCell) {
    if (cell.data === undefined || cell.data.Type === "WorkTime") return "black";
    else return "white";
  }

  private getTagCellData(): TagCellData {
    return new TagCellData({
      getColor: (cell: DXCell) => {
        if (cell.data.Type === "WorkTime") {
          return cell.data.IsBilled ? "#5cb85c" : "#d9534f";
        }
        return cell.data.BilledTimeState.Color;
      },
      getValue: (cell: DXCell) => {
        if (cell.data.Type === "WorkTime") {
          return cell.data.IsBilled ? "True" : "False";
        }
        return cell.data.BilledTimeState.Name;
      }

    });
  }

  private selectedTaskIds: number[] = [];

  public selectionChanged(e: SelectionChangedEvent) {
    // Handling the deselection of a  task

    let selectedWorkTimes = this.getSelectedWorkTimes();
    // General handling
    if (e.selectedRowsData.length === 0) return;
    let allowed = selectedWorkTimes.filter(row => !row.IsBilled);

    const lengthWithoutBilled = allowed.length;
    if (lengthWithoutBilled < e.selectedRowsData.length) {
      NotificationsService.sendWarning("Some work times has already been invoiced");
    }

    allowed = allowed.filter(row => row.IsBillable);
    if (lengthWithoutBilled > allowed.length) {
      NotificationsService.sendWarning("Some work times are not billable");
    }

    this.selectedTaskIds = e.selectedRowsData.filter(row => row.Type === "Task").map(row => row.Id);
    let selectedBillableWorkTimes = e.selectedRowsData.filter(row => row.Type === "WorkTime" && this.selectedTaskIds.includes(row.ParentId));

    allowed = Array.from(
      new Set([
        ...allowed,
        ...selectedBillableWorkTimes
      ])
    )
    let allowedKeys = allowed.map(row => row.Id) as number[];
    e.component.selectRowsByIndexes(allowedKeys);
  }

  private getSelectedWorkTimes() {
    let taskIds = this.selectedItems.filter(value => value.Id !== undefined && value.Type === "Task").map(value => value.Id) as number[];
    let workTimes = this.selectedItems.filter(value => value.ElementId !== undefined && value.Type === "WorkTime");

    // Creating the union of selected work times and work times linked to selected tasks.
    workTimes = Array.from(
      new Set([
        ...workTimes,
        ...this.lines.filter(value => value.ElementId !== undefined && value.ParentId !== undefined && value.Type === "WorkTime" && taskIds.includes(value.ParentId))
      ])
    );
    return workTimes;
  }

  // Invoicing
  // ---------
  public async validate() {
    await this.tasksService.invoice({
      taskIds: this.tasksToInvoice.map(e => e.Id!),
      invoiceId: this.invoiceId,
      workTimeIds: this.getSelectedWorkTimes().map(el => el.ElementId)
    });
    this.close();
    NotificationsService.sendSuccess('Invoice created');
    this.onInvoiceCreated.emit();
  }
}

import { Injectable } from '@angular/core';
import { LoadOptions } from 'devextreme/data';
import { ODataService } from '../../core/services/oData.service';
import { Payment } from './payment.model';
import { PaymentStatesService } from './payment-states/payment-states.service';
import { MollieService } from '../../connectors/mollie/mollie.service';
import { MolliePayment } from '../../connectors/mollie/mollie-payment/mollie-payment.model';
import { NotificationsService } from 'projects/libraries/syslink-components/src/public-api';


@Injectable({
  providedIn: 'root'
})
export class PaymentsService extends ODataService<Payment> {
  public url = 'Payment';
  public override defaultOptions: LoadOptions = {
    expand: [
      'PaymentMethodPrimary',
      'PaymentStateId',
      'PaymentTypeId'
    ]
  };
  constructor(
    private paymentStatesService: PaymentStatesService,
    private mollieService: MollieService
  ) {
    super();
  }

  public override format(params?: Partial<Payment>): Payment {
    var linkedDocumentType = params?.LinkedDocumentType;
    if (params?.PaymentMethodPrimary?.Code == "Invoice") {
      linkedDocumentType = "SaleInvoiceHeader";
    }
    else if (params?.PaymentMethodPrimary?.Code == "CreditNote") {
      linkedDocumentType = "SaleCreditNoteHeader";
    }

    var result: any = {
      ...params,
      PaymentMethodPrimary: params?.PaymentMethodPrimary?.Id,
      PaymentTypeId: params?.PaymentTypeId?.Id,
      PaymentStateId: params?.PaymentStateId?.Id,
      LinkedDocumentId: params?.LinkedDocumentId ?? params?.LinkedDocument?.Id,
      LinkedDocumentType: linkedDocumentType
    };
    delete result.AmountToReturn;
    delete result.onAdd;
    delete result.LinkedDocument;
    delete result.SelectedMollieTerminal;
    delete result.MollieDocumentNameFormatted;
    delete result.SelectedMolliePayment;
    let date: Date | undefined = result.Date;
    if (!date || date.getFullYear().toString() == "1") {
      delete result.Date;
    }
    return result;
  }

  public override async getInstance(payment?: Partial<Payment>): Promise<Payment> {
    let element = new Payment({
      Date: new Date(),
      PaymentStateId: (await this.paymentStatesService.load({ filter: ["Code eq 'paid'"] }))[0],
      ...payment,
    });

    return element;
  }

  // Mollie terminal
  // ---------------
  public async createMollieTerminalPayment(payment: Payment): Promise<Payment> {
    // Refund with mollie
    // -------------------------
    if (payment.PaymentTypeId && payment.PaymentTypeId?.Code == "Refund") {
      await this.addRefundPaymentWithMollieTerminal(payment);
      return payment;
    }

    // Paid with mollie terminal
    // -------------------------
    if (payment.PaymentTypeId?.Code != "Refund" && payment.SelectedMollieTerminal && payment.SelectedMollieTerminal.Id) {
      payment = await this.addPaymentWithMollieTerminal(payment);
    }

    return payment;
  }

  // add payment with Mollie terminal
  // --------------------------------
  private async addPaymentWithMollieTerminal(payment: Payment): Promise<Payment> {
    var localPayment = await this.mollieService.postPaymentCheckout(new MolliePayment({
      Amount: payment.Amount,
      IdMethod: "pointofsale",
      TerminalId: payment.SelectedMollieTerminal?.Id,
      Description: payment.MollieDocumentNameFormatted,
      RoundedAmount: payment.RoundedAmount,
      DocumentId: payment.DocumentId,
      DocumentType: payment.DocumentType
    }));

    payment.Id = localPayment.id;
    payment.RoundedAmount = payment.Amount;
    payment.PaymentStateId = undefined;
    return payment
  }

  // add payment with Mollie terminal
  // --------------------------------
  private async addRefundPaymentWithMollieTerminal(payment: Payment) {
    if (!payment.SelectedMolliePayment) return;
    // Check if salectedKey is empty
    // -----------------------------
    if (payment.SelectedMolliePayment?.length == 0) {
      NotificationsService.sendErrorMessage("Payment to refund is empty");
      return ;
    }
    await this.mollieService.postRefundPayment(payment.SelectedMolliePayment, payment.Note ?? "Refund");
  }

  // --------------------------------------------------
}

import { Injectable } from '@angular/core';
import { ConfigurationsService } from '../../base/modules/configurations/configurations.service';
import { Third } from './third.model';
import { ThirdRole, ThirdRoleIds } from './third-roles/third-role.model';
import { Country } from '../../base/addresses/countries/country';
import { ODataService } from '../../core/services/oData.service';
import { Language } from '../../base/languages/language';
import { LoadOptions } from 'devextreme/data';
import { CustomersService } from '../customers/customers/customers.service';
import { AppInjectorService } from 'projects/libraries/syslink-components/src/lib/services/app-injector.service';
import { Customer } from '../customers/customers/customer.model';
import { Supplier } from '../suppliers/supplier.model';
import { SuppliersService } from '../suppliers/suppliers.service';
import { SubjugationCategory } from '../../accountings/subjugation-categories/subjugation-category.model';
import { ApiService } from '../../core/api.service';
import { ThirdTitle } from '../third-titles/third-titles.service';

@Injectable({
  providedIn: 'root'
})
export class ThirdsService extends ODataService<Third> {
  public url = 'third';
  public override defaultOptions: LoadOptions = {
    expand: [
      'Roles', 'OriginId', 'LanguageId', 'CountryId', 'BankAccounts', 'TitleId', 'LegalFormId',
      'ContactInformations.AddressId.LocalityId.CountryId', 'ContactInformations.AddressId.AddressTypeId', 'ContactInformations.AddressId',
      'ContactInformations.ContactTypeId', 'ContactInformations.AddressId.PostalCodeId', 'ContactInformations.ContactQualifierId',
      'Addresses.AddressTypeId', 'Addresses.LocalityId', 'Addresses.PostalCodeId',
      'SupplierId.PurchaseTaxRateId', 'SupplierId.DeliveryTimeId', 'SupplierId.PurchaseInvoiceDocumentDelayId', 'SupplierId.PurchaseCreditNoteDocumentDelayId',
      'CustomerId.PriceListId', 'CustomerId.SaleTaxRateId', 'CustomerId.PaymentMethodId', 'CustomerId.SaleInvoiceDocumentDelayId',
      'UserId.LanguageId',
      'ThirdContacts', 'CreditSafeLineId',
      'AllRelationThirds.ThirdRelationTypeId', 'AllRelationThirds.FromThirdId', 'AllRelationThirds.ToThirdId', 'SubjugationCategoryId'
    ]
  };

  constructor(
    private api: ApiService,
    private configurationsService: ConfigurationsService
  ) {
    super();
  }

  public override format(element: Third): any {
    var result: any = {
      ...element,
      SupplierId: element.SupplierId?.Id,
      CustomerId: element.CustomerId?.Id,
      CountryId: element.CountryId?.Id,
      LegalFormId: element.LegalFormId?.Id,
      LanguageId: element.LanguageId?.Id,
      SubjugationCategoryId: element.SubjugationCategoryId?.Id,
      TitleId: element.TitleId?.Id,
      Roles: element.Roles?.map((e: ThirdRole) => {
        return {
          Id: e.Id
        };
      })
    };

    delete result['BankAccounts'];
    delete result['ThirdContacts'];
    delete result['Addresses'];
    delete result['ThirdRelations'];
    delete result['ContactInformations'];
    delete result['CreditSafeLines'];
    delete result['AllRelationThirds'];
    delete result.Fullname;

    return result;
  }

  public override async getInstance(params?: Partial<Third>): Promise<Third> {
    const customerService: CustomersService = AppInjectorService.injector.get(CustomersService);
    const suppliersService: SuppliersService = AppInjectorService.injector.get(SuppliersService);

    const thirdRoles: ThirdRole[] = (params && params.Roles) ? params.Roles : [new ThirdRole({ Id: ThirdRoleIds.Customer })];
    const customer: Customer | undefined = thirdRoles.find((e: ThirdRole) => e.Id == ThirdRoleIds.Customer) ? await customerService.getInstance() : undefined;
    const supplier: Supplier | undefined = thirdRoles.find((e: ThirdRole) => e.Id == ThirdRoleIds.Supplier) ? await suppliersService.getInstance() : undefined;

    const third = new Third({
      Roles: thirdRoles,
      CustomerId: customer,
      SupplierId: supplier,
      IsProfessional: await this.configurationsService.getConfigurationAsBoolean('isProfessional', 'Thirds.General', 'Thirds'),
      Firstname: '',
      Lastname: '',
      Name: '',
      LanguageId: new Language({ Id: await this.configurationsService.getConfigurationAsNumber('language', 'Base.General', 'Base') }),
      Subjugated: await this.configurationsService.getConfigurationAsBoolean('subjugated', 'Thirds.General', 'Thirds'),
      CountryId: new Country({ Id: await this.configurationsService.getConfigurationAsNumber('country', 'Thirds.General', 'Thirds') }),
      SubjugationCategoryId: new SubjugationCategory({ Id: await this.configurationsService.getConfigurationAsNumber('subjugationCategory', 'Accountings.Thirds', 'Accountings') }),
      TitleId: new ThirdTitle({ Id: await this.configurationsService.getConfigurationAsNumber('title', 'Thirds.Private', 'Thirds') }),
      LegalFormId: { Id: 1 },
      ...params
    })
    return third;
  }

  public async removeThirdRole(elementId: number, roleId: number) {
    return await this.api.sendRequest('/api/odata/Third/' + elementId + '/Roles/' + roleId + '/$ref', 'DELETE');
  }

  public async GetDocumentRelation(thirdId:number){
    return await this.api.sendRequest('/api/Third/GetDocumentRelation/'+thirdId)
  }
}

<syslink-switch
  label="Only show related third"
  [(model)]="showRelatedThird"
  (modelChange)="ChangeThirdFilter()"
></syslink-switch>

<syslink-select
    #ThirdSelect
    label="Third"
    [store]="thirdsService.store"
    [filter]="selectFilter"
    [expand]="['Addresses.LocalityId','Addresses.PostalCodeId', 'ContactInformations.ContactTypeId', 'ContactInformations.AddressId.LocalityId', 'ContactInformations.AddressId.PostalCodeId','CustomerId.SaleInvoiceDocumentDelayId']"
    [select]="['Id', 'Fullname','IsProfessional']"
    displayKey="Fullname"
    [(model)]="element.ThirdId"
    (modelChange)="onThirdChanged($event)"
    [detailsUrl]="detailsUrl"
    [canAdd]="true"
    [canView]="true"
    [canRefresh]="true"
></syslink-select>

<div class="h-100 d-flex mb-1 pb-1 justify-content-center align-items-center gap-2">
    <i
        class="card text-center text-white bg-{{element.HasPrincipal? 'primary':'gray'}} p-1"
        role="button"
        (click)="updatePrincipal()"
    >PRI</i>
    <i
        class="card text-center text-white bg-{{element.HasSecondary? 'primary':'gray'}} p-1"
        role="button"
        (click)="updateSecondary()"
    >SEC</i>
    <i
        class="card text-center text-white bg-{{element.HasCC? 'primary':'gray'}} p-1"
        role="button"
        (click)="element.HasCC = !element.HasCC"
    >CC</i>
    <i
        class="card text-center text-white bg-{{element.HasCCI? 'primary':'gray'}} p-1"
        role="button"
        (click)="element.HasCCI = !element.HasCCI"
    >CCI</i>
    <i *ngIf="showDeliveryAddress"
        class="card text-center text-white bg-{{element.UsedDeliveryAddress? 'primary':'gray'}} p-1"
        role="button"
        (click)="element.UsedDeliveryAddress = !element.UsedDeliveryAddress"
    >POST</i>
</div>

<syslink-select
    #AddressSelect
    *ngIf="element.ThirdId && element.ThirdId.Id && (element.ThirdId.Addresses.length > 1 || element.AddressId)"
    label="Address"
    [items]="element.ThirdId.Addresses"
    displayKey="FormattedAddress"
    [(model)]="element.AddressId"
></syslink-select>

<syslink-select
    #DeliveryAddressSelect
    *ngIf="showDeliveryAddress && element.UsedDeliveryAddress && element.ThirdId && element.ThirdId.Id"
    label="Delivery address"
    [items]="element.ThirdId.Addresses"
    displayKey="FormattedAddress"
    [(model)]="element.DeliveryAddressId"
></syslink-select>
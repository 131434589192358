import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ModalComponent, NotificationsService } from 'projects/libraries/syslink-components/src/public-api';
import { MailTemplate } from '../mail-templates/mail-template.model';
import { MailTemplatesService } from '../mail-templates/mail-templates.service';
import { MailTemplateType } from '../mail-template-types/mail-template-type.model';
import { MailTemplateTypesService } from '../mail-template-types/mail-template-types.service';
import { ThirdContactInformationsService } from '../../thirds/thirds/third-contacts/third-contact-informations/third-contact-informations.service';
import { ThirdContactTypesService } from '../../thirds/thirds/third-contacts/third-contact-types/third-contact-types.service';
import { ThirdContactQualifiersService } from '../../thirds/thirds/third-contacts/third-contact-qualifiers/third-contact-qualifiers.service';
import { ConfigurationsService } from '../../base/modules/configurations/configurations.service';
import { Third } from '../../thirds/thirds/third.model';
import { ThirdsService } from '../../thirds/thirds/thirds.service';
import { ODataService } from '../../core/services/oData.service';
import { CustomTagboxComponent } from 'projects/libraries/syslink-components/src/lib/form/custom-tagbox/custom-tagbox.component';
import { ThirdModalComponent } from 'projects/libraries/syslink-components/src/lib/modal/third-modal/third-modal.component';
import { Router, TitleStrategy } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ThirdContactInformation } from '../../thirds/thirds/third-contacts/third-contact-informations/third-contact-information.model';
import { ThirdContactType } from '../../thirds/thirds/third-contacts/third-contact-types/third-contact-type.model';
import { ThirdContactQualifier } from '../../thirds/thirds/third-contacts/third-contact-qualifiers/third-contact-qualifier.model';
import { MailModel } from '../../connectors/mail/mail.model';
import { MailService } from '../../connectors/mail/mail.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-mail-modal',
  templateUrl: './mail-modal.component.html',
  styleUrls: ['./mail-modal.component.scss']
})
export class MailModalComponent {
  @Input() public mailTemplate?: MailTemplate;

  private _mailTemplateType: MailTemplateType = new MailTemplateType();
  @Input() public set mailTemplateType(value: MailTemplateType) {
    this._mailTemplateType = value;
    this.loadFilterMailTemplate();
  }
  public get mailTemplateType(): MailTemplateType {
    return this._mailTemplateType;
  }

  @Input() public element: any;
  @Input() public documentType: string = '';
  @Input() public filterToContactInformation: string | string[] = ["ContactTypeId.Code eq 'email'"];

  @Input() public mailModel: MailModel = new MailModel();
  @Output() mailModelChange: EventEmitter<MailModel> = new EventEmitter<MailModel>();

  @ViewChild('modal') modal?: ModalComponent;
  @ViewChild('toTagBox') public toTagBox: CustomTagboxComponent = new CustomTagboxComponent();
  @ViewChild('ccTagBox') public ccTagBox: CustomTagboxComponent = new CustomTagboxComponent();
  @ViewChild('cciTagBox') public cciTagBox: CustomTagboxComponent = new CustomTagboxComponent();
  @ViewChild('addToEmailModal') public addToEmailModal: ModalComponent = new ModalComponent();
  @ViewChild('ThirdModal') public ThirdModal: ThirdModalComponent = new ThirdModalComponent();

  public filterMailTemplate: string | string[] = "";

  public addEmailToThirdfields: any[] | undefined = undefined;
  public specificThirdEmails: string = "";
  public specificField: string = "";

  constructor(
    public mailTemplateTypesService: MailTemplateTypesService,
    public mailTemplatesService: MailTemplatesService,
    public thirdContactInformationsService: ThirdContactInformationsService,
    public thirdContactTypesService: ThirdContactTypesService,
    public thirdContactQualifiersService: ThirdContactQualifiersService,
    private configurationsService: ConfigurationsService,
    public thirdsService: ThirdsService,
    private router: Router,
    private translateService: TranslateService,
    public datePipe: DatePipe,
    private mailService: MailService
  ) {

  }

  // File
  // ----
  @ViewChild('fileManagerModal') public fileManagerModal: ModalComponent = new ModalComponent();

  public onFileSelectedChange(selectdFileIds: Array<string>) {
    this.mailModel.FileIds = selectdFileIds.map(fileId => Number(fileId));
  }

  // Modal
  // -----
  public async open() {
    await this.loadFilterMailTemplate();
    await this.getFormattedFrom();
    this.modal?.open();
  }

  public close() {
    this.modal?.close();
  }

  public async onValidateButtonClicked() {
    if (this.mailModel.ToThirdContactInformationId.length == 0) {
      NotificationsService.sendErrorMessage("Third cannot be empty");
      return;
    }
    this.formatMail();
    await this.mailService.sendMail(this.mailModel);
    this.close();
  }

  public formatMail() {
    this.mailModel.ToThirdEmails = this.mailModel.ToThirdContactInformationId.map((communication: ThirdContactInformation): string => {
      return communication.Value ?? "";
    });
    this.mailModel.CCThirdEmails = this.mailModel.CCThirdContactInformationId.map((communication: ThirdContactInformation): string => {
      return communication.Value ?? "";
    });
    this.mailModel.CCIThirdEmails = this.mailModel.CCIThirdContactInformationId.map((communication: ThirdContactInformation): string => {
      return communication.Value ?? "";
    });
  }

  // Mail template
  // -------------  
  public async loadFilterMailTemplate() {
    if (!this.mailTemplateType.Code || this.mailTemplateType.Code == "") return;
    this.filterMailTemplate = ["MailTemplateTypeId.Code eq '" + this.mailTemplateType.Code + "'"];
    if (!this.mailTemplate) {
      this.mailTemplate = (await this.mailTemplatesService.load({ filter: this.filterMailTemplate }))[0];
      this.loadMailTemplateData();
    }
  }

  public async loadMailTemplateData() {
    if (!this.mailTemplate) return;
    this.mailModel.SendReport = this.mailTemplate.AttachFile ?? false;
    this.mailModel.Subject = ((new DOMParser()).parseFromString(this.mailTemplate.Subject ?? "", 'text/html')).body.textContent || "";
    this.mailModel.Content = this.mailTemplate.Content ?? "";
    this.updateStringMailModal();
  }

  private updateStringMailModal() {
    if (this.mailModel.Subject == undefined || this.mailModel.Content == undefined) return;
    Object.entries(this.element).forEach(([key, value]) => {
      if (value instanceof Date) {
        value = this.datePipe.transform(value, 'dd/MM/YYYY');
      }

      this.mailModel.Subject = this.mailModel.Subject.replaceAll(`{{${this.mailTemplateType.Code + "." + key}}}`, value != null ? `${value}` : "");
      this.mailModel.Content = this.mailModel.Content.replaceAll(`{{${this.mailTemplateType.Code + "." + key}}}`, `${value}`);
    })
  }

  // From
  // ----
  public async computeFromEmail() {
    const companyId = await this.configurationsService.getConfigurationAsNumber("company", "Base.General", "Base");
    const fromThirdId: Third | undefined = (await this.thirdsService.load({ filter: ['Id eq ' + companyId] }))[0];
    this.mailModel.FromThirdId = fromThirdId;

    if (fromThirdId == undefined) return;

    if ((this.mailModel.FromThirdEmail == undefined || this.mailModel.FromThirdEmail == "") && fromThirdId.DisplayEmail != undefined) {
      this.mailModel.FromThirdEmail = fromThirdId.DisplayEmail;
    }
  }

  public async getFormattedFrom() {
    if (this.mailModel.FromThirdEmail == undefined) {
      await this.computeFromEmail();
    }
    this.mailModel.FromThird = this.mailModel.FromThirdId?.Fullname + " - " + this.mailModel.FromThirdEmail;
  }


  public async onCustomeValue(text: string, field: string) {
    if (!text.length) return;
    this.specificField = field;

    var items: any[] | undefined = this.getTagBoxItems();
    if (items && items.length > 0) {
      this.addCommunicationToField(items[0]);
      return;
    }
    this.specificThirdEmails = text;
    this.addToEmailModal.open();
  }

  public ValidateEmail(text: string): boolean {
    var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (text.match(validRegex)) {
      return true;
    }
    NotificationsService.sendErrorMessage("Email is not valid");
    return false;
  }

  public async onLinkToThirdClicked() {
    var contactType = (await this.thirdContactTypesService.load({ filter: ["Code eq 'email'"] }))[0];
    this.addEmailToThirdfields = [];
    this.addEmailToThirdfields.push(
      { Key: "Public", Type: "bool", Name: "Public", ColumnWidth: 12, Value: "true" },
      { Key: "ContactType", Type: "one-to-one", Name: "Type", ColumnWidth: 12, Value: contactType.Id, Store: ODataService.generateStore("ContactType"), Disabled: true },
      { Key: "ContactQualifier", Type: "one-to-one", Name: "Qualifier", ColumnWidth: 12, Value: null, Store: ODataService.generateStore("ContactQualifier") },
      { Key: "UsedForQuote", Type: "bool", Name: "Used for quotes", ColumnWidth: 12, Value: "true" },
      { Key: "UsedForInvoice", Type: "bool", Name: "Used for invoices", ColumnWidth: 12, Value: "true" },
      { Key: "UsedForNewsletter", Type: "bool", Name: "Used for Newsletters", ColumnWidth: 12, Value: "true" },
      { Key: "Value", Type: "string", Name: "value", ColumnWidth: 12, Value: this.specificThirdEmails }
    );
    this.ThirdModal.autoform?.ngOnInit();
    this.ThirdModal.open();
    this.specificThirdEmails = "";
    this.addToEmailModal.close();
  }

  public async onAddEmailToThird(e: any) {
    var isPublic: boolean | undefined = undefined;
    var contactType: ThirdContactType | undefined = undefined;
    var contactQualifier: ThirdContactQualifier | undefined = undefined;
    var usedForQuote: boolean | undefined = undefined;
    var usedForInvoice: boolean | undefined = undefined;
    var usedForNewsletter: boolean | undefined = undefined;
    var value: string | undefined = undefined;

    e.extraFields.forEach(async (data: any) => {
      if (data.Key == "Public")
        isPublic = data.Value == "true" ? true : false;
      if (data.Key == "ContactType")
        contactType = new ThirdContactType({ Id: data.Value });
      if (data.Key == "ContactQualifier")
        contactQualifier = new ThirdContactQualifier({ Id: data.Value });
      if (data.Key == "UsedForQuote")
        usedForQuote = data.Value == "true" ? true : false;
      if (data.Key == "UsedForInvoice")
        usedForInvoice = data.Value == "true" ? true : false;
      if (data.Key == "UsedForNewsletter")
        usedForNewsletter = data.Value == "true" ? true : false;
      if (data.Key == "Value")
        value = data.Value;
    });
    var contactInformation = await this.thirdContactInformationsService.insert(this.thirdContactInformationsService.format(
      await this.thirdContactInformationsService.getInstance({
        IsPublic: isPublic,
        ThirdId: e.third,
        Value: value,
        ContactTypeId: contactType,
        ContactQualifierId: contactQualifier,
        UsedForInvoice: usedForInvoice,
        UsedForNewsletter: usedForNewsletter,
        UsedForQuote: usedForQuote
      })));
    if (!contactInformation.Id) return;
    contactInformation = await this.thirdContactInformationsService.findByID(contactInformation.Id, { select: ['Id', 'Value', 'ThirdId.Fullname'] });
    this.addCommunicationToField(contactInformation);
    NotificationsService.sendSuccess("Record created")
  }

  public onAddNewThirdClicked() {
    this.specificThirdEmails = "";
    const url = this.router.serializeUrl(this.router.createUrlTree(["/thirds/customers/new"]));
    window.open(url, '_blank');
    this.addToEmailModal.close();
  }

  public async onUsedSpecificMailClicked() {
    var communication = await this.thirdContactInformationsService.getInstance({
      Value: this.specificThirdEmails,
      ThirdId: await this.thirdsService.getInstance({ Fullname: this.translateService.instant("Other") })
    });

    this.addCommunicationToField(communication);
    this.addToEmailModal.close();
    this.specificThirdEmails = "";
  }

  public addCommunicationToField(contact: ThirdContactInformation) {
    switch (this.specificField) {
      case "TO":
        this.mailModel.ToThirdContactInformationId.push(contact);
        break;
      case "CC":
        this.mailModel.CCThirdContactInformationId.push(contact);
        break;
      case "CCI":
        this.mailModel.CCIThirdContactInformationId.push(contact);
        break;
    }
    this.specificField = "";
  }

  public getTagBoxItems(): any[] | undefined {
    switch (this.specificField) {
      case "TO":
        return this.toTagBox?.syslink_select?.selectComponent?.instance.getDataSource().items();
      case "CC":
        return this.ccTagBox?.syslink_select?.selectComponent?.instance.getDataSource().items();
      case "CCI":
        return this.cciTagBox?.syslink_select?.selectComponent?.instance.getDataSource().items();
    }
    return undefined;
  }
}
import { Injectable } from '@angular/core';
import { ODataService } from '../../core/services/oData.service';
import { PrivateConfiguration } from './private-configuration.model';
import { environment } from 'projects/erp-app/src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PrivateConfigurationsService extends ODataService<PrivateConfiguration> {
  public url = 'PrivateConfiguration';
  public allConfiguration: PrivateConfiguration[] = [];

  static allConfigurationStatics: any = [];
  public setConfigurations() {
    PrivateConfigurationsService.allConfigurationStatics = this.allConfiguration;
  }

  static getDefaultPrecision(): number {
    if (PrivateConfigurationsService.allConfigurationStatics.length > 0) {
      return parseInt((PrivateConfigurationsService.allConfigurationStatics.find((p: PrivateConfiguration) => p.Key == "TotalPrecision")).Value);
    }
    return environment.defaultPrecision;
  }
  static getPrecision(key: string = 'TotalPrecision'): number {
    
    if (PrivateConfigurationsService.allConfigurationStatics.length > 0) {
      return parseInt((PrivateConfigurationsService.allConfigurationStatics.find((p: PrivateConfiguration) => p.Key == key)).Value);
    }
    return PrivateConfigurationsService.getDefaultPrecision();
  }
}
import { Component, OnInit, ViewChild } from '@angular/core';
import { AccountMove } from '../account-move.model';
import { ConfirmModalComponent, NotificationsService, PageComponent, SyslinkToolbarAction, SyslinkToolbarActionButton } from 'projects/libraries/syslink-components/src/public-api';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AppInjectorService } from 'projects/libraries/syslink-components/src/lib/services/app-injector.service';
import { AccountMovesService } from '../account-move.service';
import { ActivatedRoute } from '@angular/router';
import { AccountMoveStatus } from '../account-move-status/account-move-status.model';
import { DocumentStatusChangedEvent, DocumentStatusesComponent } from '../../../base/documents/document-statuses/document-statuses.component';
import { AccountMoveStatusService } from '../account-move-status/account-move-status.service';
import { AccountMoveLine } from '../../account-move-lines/account-move-line.model';
import { AccountMoveHeaderStatusService } from '../account-move-header-status/account-move-header-status.service';
import { AccountMoveHeaderStatus } from '../account-move-header-status/account-move-header-status.model';
import { AccountMoveLinesService } from '../../account-move-lines/account-move-line.service';
import { Entity } from '../../../core/models/entity';
import { ODataService } from '../../../core/services/oData.service';
import { AccountMoveLineGridItemsComponent } from '../../account-move-lines/account-move-line-grid/account-move-line-grid-items/account-move-line-grid-items.component';

@Component({
  selector: 'app-account-move-details',
  templateUrl: './account-move-details.component.html',
  styleUrls: ['./account-move-details.component.scss']
})
export class AccountMoveDetailsComponent extends PageComponent implements OnInit {

  public element: AccountMove = new AccountMove();

  public override toolbarActions: SyslinkToolbarAction[] = [];
  public availableStatuses: AccountMoveStatus[] = [];

  @ViewChild('deleteConfirm') deleteConfirm: ConfirmModalComponent = new ConfirmModalComponent;
  @ViewChild('documentStatuses') documentStatuses: DocumentStatusesComponent = new DocumentStatusesComponent();
  @ViewChild('accountMoveLineGridItems') accountMoveLineGridItems?: AccountMoveLineGridItemsComponent;

  constructor(
    private ngxUiLoaderService: NgxUiLoaderService,
    private accountMovesService: AccountMovesService,
    private accountMoveStatusService: AccountMoveStatusService,
    private accountMoveHeaderStatusService: AccountMoveHeaderStatusService,
    public override activatedRoute: ActivatedRoute,
    private accountMoveLinesService: AccountMoveLinesService
  ) {
    super();
  }

  override async ngOnInit(): Promise<void> {
    super.ngOnInit();
    this.activatedRoute.data.subscribe(async ({ element }) => {
      this.ngxUiLoaderService.start();

      this.element = element;
      if (!this.element || !this.element.Id) {
        this.element = await this.accountMovesService.getInstance(this.element);
      }

      await this.refresh();

      this.ngxUiLoaderService.stop();
    });

  }
  public async refresh() {
    this.updateBreadCrumb(this.getFormattedTitle());
    this.initToolbar();
    this.initStatusBar();
    this.formatLine();
  }

  private formatLine() {
    this.element.AccountMoveLines.map((line: AccountMoveLine) => {
      if (line.Date) {
        let date: Date = new Date(line.Date);
        if (!date || date.getFullYear().toString() == "1" || date.getFullYear().toString() == "0") {
          line.Date = undefined;
        }
      }
      if (line.Deadline) {
        let date: Date = new Date(line.Deadline);
        if (!date || date.getFullYear().toString() == "1" || date.getFullYear().toString() == "0") {
          line.Deadline = undefined;
        }
      }
    });
  }

  private initToolbar() {
    this.toolbarActions = [];

    this.toolbarActions.push(new SyslinkToolbarActionButton({ icon: 'save', text: 'Save', onClick: () => this.save(), location: 'before', inMenu: 'never', hotkey: 'control.s', visible: this.element.Id ? this.authService.hasPermission(this.basePermissionKey + '.update') : this.authService.hasPermission(this.newBasePermissionKey + '.add') }));
    if (this.element.Id) {
      this.toolbarActions.push(new SyslinkToolbarActionButton({ code: 'delete', icon: 'trash', text: 'Delete', onClick: () => this.delete(), visible: this.authService.hasPermission(this.basePermissionKey + '.delete') }));
    }
  }

  public canEditDocument(): boolean {
    return !this.hasActiveStatus('Move.ExportedToBOB') && !this.hasActiveStatus('Move.Closed');
  }

  public hasActiveStatus(statusCode: string): boolean {
    if (!this.element || !this.element.Statuses) return false;
    return this.element.Statuses?.find((status: AccountMoveHeaderStatus) => status.StatusId?.Code === statusCode) != undefined;
  }

  // Document Statuses
  // -----------------
  public initStatusBar() {
    this.accountMoveStatusService.load().then((statuses: any[]) => {
      this.documentStatuses.statuses = statuses;
      this.documentStatuses.documentStatuses = this.element.Statuses || [];
      this.documentStatuses.refreshItems();
    });
  }

  public async onDocumentStatusChanged(event: DocumentStatusChangedEvent) {
    if (!this.authService.hasPermission(this.newBasePermissionKey + '.ChangeStatus')) {
      NotificationsService.sendErrorMessage("You do not have the required permission!");
      return;
    }

    if (!event.status || !event.status.Sequence || !this.element || !this.element.CurrentStatusLink || !this.element.CurrentStatusLink.StatusId) {
      NotificationsService.sendErrorMessage("Status error");
      return;
    }

    if (event.status.Sequence <= (this.element.CurrentStatusLink.StatusId.Sequence ?? 5)) {
      NotificationsService.sendErrorMessage("This status is impossible");
      return;
    }

    if (!this.accountMovesService.canSave(this.element)) {
      this.initStatusBar();
      return;
    }
    var statuses = await this.accountMoveStatusService.load();
    var status = statuses.find((s: any) => s.Code == event.status.Code);
    var newStatus: any = await this.accountMoveHeaderStatusService.getInstance();
    var intermediateStatus: any = statuses.filter((e: AccountMoveStatus) => {
      return e.Sequence && status?.Sequence && this.element.CurrentStatusLink?.StatusId?.Sequence
        && status?.Sequence != e.Sequence
        && e.Sequence > this.element.CurrentStatusLink?.StatusId?.Sequence
        && e.Sequence < status?.Sequence;
    });

    for (let index = 0; index < intermediateStatus.length; index++) {
      if(intermediateStatus[index].Code == "Move.ExportedToBOB" && (this.element.ThirdId?.AccountingBOBReference == null || this.element.ThirdId?.AccountingBOBReference == '')){
        NotificationsService.sendErrorMessage("BOB reference cannot be empty");
        return ;
      }
      var intermediateNewStatus: any = await this.accountMoveHeaderStatusService.getInstance();
      intermediateNewStatus.StatusId = intermediateStatus[index];
      intermediateNewStatus.Date = new Date(intermediateNewStatus.Date.setSeconds(intermediateNewStatus.Date.getSeconds() - (index + 1)));
      this.element.Statuses?.push(intermediateNewStatus);
    }
    if(status && status.Code == "Move.ExportedToBOB" && (this.element.ThirdId?.AccountingBOBReference == null || this.element.ThirdId?.AccountingBOBReference == '')){
      NotificationsService.sendErrorMessage("BOB reference cannot be empty");
      return ;
    }
    newStatus.StatusId = status;
    try {
      this.element.CurrentStatusLink = newStatus;
      this.element.Statuses?.push(newStatus);
      this.documentStatuses.refreshItems();

      await this.save();

      if (this.element.Id) {
        const element = await this.accountMovesService?.findByID(this.element.Id, { select: ['No'], expand: [] });
        this.element.No = element?.No;
        this.refresh();
      }
    } catch (error) {
      this.element.Statuses = this.element.Statuses?.filter((s: AccountMoveHeaderStatus) => { return s.StatusId?.Sequence != newStatus.StatusId?.Sequence });
      this.element.CurrentStatusLink = this.element.Statuses?.find((s: AccountMoveHeaderStatus) => s.StatusId?.Sequence == ((newStatus.StatusId?.Sequence ?? 3) - 1));
      setTimeout(() => {
        this.documentStatuses.refreshItems()
        this.ngxUiLoaderService.stop();
      }, 50);
    }
  }

  // Create/Update
  // -------------
  public async save(showMessage: boolean = true) {
    if ((!this.element.Id && !this.authService.hasPermission(this.basePermissionKey + '.add')) || (this.element.Id && !this.authService.hasPermission(this.basePermissionKey + '.update'))) {
      NotificationsService.sendErrorMessage("You do not have the required permission!");
      return;
    }

    if (!this.accountMovesService.canSave(this.element)) {
      this.initStatusBar();
      return;
    }

    this.ngxUiLoaderService.start();
    var isNew = false;

    if (!this.element.Id) {
      const elementId = (await this.accountMovesService.insert(await this.accountMovesService?.format(this.element))).Id;
      this.element.Id = elementId;
      isNew = true;
    } else {
      await this.accountMovesService?.update(this.element.Id, await this.accountMovesService?.format(this.element));
    }

    await this.saveLines();
    await this.saveStatus();

    if (isNew == true) {
      AppInjectorService.config.setModificationGuard(false);
      this.goToUrl('../' + this.element.Id);
    }

    if (showMessage == true) {
      NotificationsService.sendSuccess("Record updated");
    }
    this.formatLine();
    // TODO Check better method
    setTimeout(() => {
      this.ngxUiLoaderService.stop();
      AppInjectorService.config.setModificationGuard(false);
    }, 100);
  }

  public showGrid:boolean = true;
  public async saveStatus() {
    if (!this.element.Statuses) return;
    this.showGrid = false;
    for (let index = 0; index < this.element.Statuses.length; index++) {
      if (this.element.Statuses[index].Id == undefined) {
        this.element.Statuses[index].MoveId = await this.accountMovesService.format(this.element);
        var newElement: any = this.element.Statuses[index];
        var newElementStatusId = newElement.StatusId;
        newElement.MoveId = {Id:newElement.MoveId.Id};
        this.element.Statuses[index] = await this.accountMoveHeaderStatusService.insert(await this.accountMoveHeaderStatusService.format(newElement));
        this.element.Statuses[index].StatusId = newElementStatusId;
      }
    }
    setTimeout(()=>{
      this.showGrid = true;
    },200);
    // console.log(this.canEditDocument());
    // if(!this.accountMoveLineGridItems) return;
    // this.accountMoveLineGridItems.disabled = this.canEditDocument();
    // this.accountMoveLineGridItems.updateListOptions();
    // this.accountMoveLineGridItems.gridComponent?.grid?.ngOnInit();
    // this.accountMoveLineGridItems.gridComponent?.grid?.ngAfterViewInit();
    // this.accountMoveLineGridItems.gridComponent?.grid?.instance.refresh();
    
    // console.log(this.element.Statuses);

  }

  public async saveLines(): Promise<void> {
    if (!this.element.AccountMoveLines) return;
    var filter: any = [];
    filter = ["AccountMoveId.Id eq " + this.element.Id];
    this.element.AccountMoveLines.map((d: AccountMoveLine) => { d.AccountMoveId && d.AccountMoveId.Id == this.element.Id });
    this.element.AccountMoveLines.map((d: AccountMoveLine) => {
      if (d.Id && typeof d.Id !== 'number') {
        d.Id = undefined;
      }
      d.AccountMoveId = this.element;
    });
    var existingElement = await this.accountMoveLinesService.load({ filter: filter, expand: this.getDocumentLineExpand() });

    await this.createOrUpdateArray(this.accountMoveLinesService, this.element.AccountMoveLines, existingElement, ['Date', 'Debit', 'Credit', 'Deadline', 'ThirdId', 'SaleDocumentId', 'AccountId', 'TaxId']);

    this.element.AccountMoveLines = await this.accountMoveLinesService.load({ filter: filter, expand: this.getDocumentLineExpand() });


    // this.saleDocumentContent.documentLines?.treelist?.treelist?.instance.getDataSource().items().forEach((i: any) => {
    //   this.saleDocumentContent.documentLines?.treelist?.treelist?.instance.expandRow(i.key);
    // });
    // this.saleDocumentContent.documentLines?.treelist?.treelist?.instance.refresh();
  }

  public getDocumentLineExpand(): string[] {
    var result = ['AccountId.NameTranslationId', 'ThirdId', 'SaleDocumentId', 'TaxId'];
    return result;
  }

  public async createOrUpdateArray<T extends Entity>(service: ODataService<T>, listElements: any[], existingElement: any[], keyToCompare: string[] = [], discountService?: any): Promise<void> {

    // Get element to Add
    // ------------------
    var elementToAdd = listElements.filter((s: any) => { return s.Id == undefined || (s.Id != undefined && s.Id <= 0) });
    for (const s of elementToAdd) {
      s.Id = undefined;
      var temp = await service.insert(service.format(s));
      s.Id = temp.Id;
    };

    // Get element to Update
    // ---------------------
    var elementToUpdate = listElements.filter((obj1: any) => {
      const obj2: any = existingElement.find((obj2: any) => obj2.Id === obj1.Id);
      return keyToCompare.some((cle: any) => {
        if (obj2 == undefined) return true;
        else if ((obj1[cle] !== undefined && obj2[cle] === undefined) || (obj1[cle] === undefined && obj2[cle] !== undefined)) return true;
        else if (typeof obj1[cle] == 'object' && typeof obj2[cle] == 'object' && ((obj1[cle] === undefined && obj2[cle] === undefined) || (obj1[cle] === null && obj2[cle] === null))) return false;
        else if ((typeof obj1[cle] != 'object' && typeof obj2[cle] == 'object') || (typeof obj1[cle] == 'object' && typeof obj2[cle] != 'object')) return true;
        else if (typeof obj1[cle] == 'object' && typeof obj2[cle] == 'object' && (cle == "Discount" || cle == "Margin") && (obj1[cle].Value != obj2[cle].Value || obj1[cle].IsDiscountFixed != obj2[cle].IsDiscountFixed)) return true;
        else if (typeof obj1[cle] == 'object' && typeof obj2[cle] == 'object' && ((obj1[cle] == undefined && obj2[cle] != undefined) || (obj2[cle] == undefined && obj1[cle] != undefined) || obj1[cle].Id !== obj2[cle].Id)) return true;
        else if (typeof obj1[cle] == 'object' && typeof obj2[cle] == 'object' && obj1[cle].Id === obj2[cle].Id) return false;
        else if (obj1[cle] !== obj2[cle]) return true;
        else return false;
      })
    });
    for (const s of elementToUpdate) {
      if (s.Id) {
        await service.update(s.Id, service.format(s))
      }
    };

    // Get element to Delete
    // ---------------------
    var elementToDelete = existingElement.filter((obj1: any) =>
      !listElements.some((obj2: any) => obj2.Id === obj1.Id)
    );
    for (const s of elementToDelete) {
      if (s.Id) {
        await service.remove(s.Id);
      }
    };
  }

  // Delete
  // ------
  public delete() {
    this.deleteConfirm.open();
  }

  public async accountMoveDelete() {
    if (!this.element?.Id) return

    await this.accountMovesService.remove(this.element.Id);
    NotificationsService.sendInfo('Record deleted');
    this.goToUrl('../');
  }

  public getFormattedTitle(): string {
    let formattedTitle = this.translateService.instant("Account move") + " " + (this.element?.No ? this.element.No : '');
    return formattedTitle;
  }
}

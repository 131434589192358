import { MetaFile } from "../../../connectors/filesystem/meta-file";
import { SaleContract } from "../../sale-contracts/sale-contracts/sale-contract.model";
import { SaleDocument } from "../../sale-documents/sale-documents/sale-document.model";
import { SaleInvoiceFinance } from "../sale-invoice-finances/sale-invoice-finance.model";
import { SaleInvoiceHeaderHeaderStatus } from "../sale-invoice-header-header-statuses/sale-invoice-header-header-status.model";

export class SaleInvoice extends SaleDocument {
    public override DocumentFinances?: SaleInvoiceFinance[];
    public override CurrentStatusLink?: SaleInvoiceHeaderHeaderStatus;
    public override Statuses: SaleInvoiceHeaderHeaderStatus[] = [];
    public FirstReminder : MetaFile | null = null;
    public SecondReminder : MetaFile | null = null;
    public ThirdReminder : MetaFile | null = null;
    public PeriodLabel?:string;

    public SaleContractId?:SaleContract;

    constructor(entity?: Partial<SaleInvoice>) {
        super();
        Object.assign(this, entity);
    }

    public getReminderFromNumber(number : number) : MetaFile | null{
      switch(number){
        case 1 :
          return this.FirstReminder;
        case 2 :
          return this.SecondReminder;
        case 3 :
          return this.ThirdReminder;
        default :
          throw new Error("Wrong reminder number.");
      }
    }
}

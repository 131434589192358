import { Injectable } from '@angular/core';
import { ApiService } from '../../../core/api.service';
import { Document } from './document.model';
import { PrivateConfigurationsService } from '../../private-configurations/private-configurations.service';
import { DocumentLine, DocumentLineType } from '../document-lines/document-line.model';
import { Payment } from '../../payments/payment.model';

@Injectable({
  providedIn: 'root'
})
export abstract class DocumentsService {
  public url: string = 'document';

  constructor(
    private api: ApiService,
    // public reportsService: ReportsService
  ) {

  }

  public computeDeadline(startDate: Date, documentDelayId: number): Promise<Date> {
    return this.api.sendRequest('/api/SaleDocument/computeDeadline', 'POST', {
      Date: startDate,
      DocumentDelayId: documentDelayId,
    });
  }


  public TransformToType(url: string, toType: string, documentId: number, type:string, idList: number[] = [], third:number|undefined = undefined, fromDate: Date = new Date(), toDate: Date = new Date()): Promise<number> {
    return this.api.sendRequest('/api/' + url + '/' + documentId + '/transformTo' + toType, 'POST', { idList, type, third, fromDate, toDate });
  }

  public CopyToType(url: string, toType: string, documentId: number, subject: string, third: number, idList: number[] = [], fromDate: Date = new Date(), toDate: Date = new Date()): Promise<number> {
    return this.api.sendRequest('/api/' + url + '/' + documentId + '/copyTo' + toType, 'POST', { idList, subject, third, fromDate, toDate });
  }

  public ConvertToType(url: string, toType: string, documentId: number, subject: string, third: number, idList: number[] = [], fromDate: Date = new Date(), toDate: Date = new Date()): Promise<number> {
    return this.api.sendRequest('/api/' + url + '/' + documentId + '/convertTo' + toType, 'POST', { idList, subject, third });
  }

  public computeDocumentPrice(element : Document) : any {
    var productLine = element.Lines?.filter((l: DocumentLine) => l.LineType == DocumentLineType.product) ?? [];
    var variousLine = element.Lines?.filter((l: DocumentLine) => l.LineType == DocumentLineType.various) ?? [];
    if (productLine == undefined && variousLine == undefined) {
      element.ExTaxTotal = 0;
      element.ExTaxTotalWithoutGlobalDiscount = 0;
      element.InTaxTotal = 0;
      element.DocumentFinances = [];
      return element;
    }
    var exTaxTotal = variousLine?.reduce((acc, obj: any) => acc + obj.ExTaxTotalPrice ?? 0, productLine?.reduce((acc, obj: any) => acc + obj.ExTaxTotalPrice ?? 0, 0)) ?? 0;
    element.ExTaxTotalWithoutGlobalDiscount = parseFloat( exTaxTotal.toFixed(PrivateConfigurationsService.getPrecision('TotalPrecision')));
    element.ExTaxTotal = parseFloat( (exTaxTotal * ((100 - (element.GlobalDiscountValue ?? 0)) / 100)).toFixed(PrivateConfigurationsService.getPrecision('TotalPrecision')));
    var inTaxTotal = variousLine?.reduce((acc, obj: any) => acc + obj.InTaxTotalPrice ?? 0, productLine?.reduce((acc, obj: any) => acc + obj.InTaxTotalPrice ?? 0, 0)) ?? 0;
    element.InTaxTotal = parseFloat( (inTaxTotal * ((100 - (element.GlobalDiscountValue ?? 0)) / 100)).toFixed(PrivateConfigurationsService.getPrecision('TotalPrecision')));
    var productAndVariousLine = [...productLine, ...variousLine];
    var documentFinance: any[] = [];

    const lineDictionaries: { [key: string]: any[] } = productAndVariousLine
      .reduce((acc: { [key: string]: any[] }, line: any) => {
        const key = line.TaxRateId.Code;
        acc[key] = acc[key] || [];
        acc[key].push(line);
        return acc;
      }, {});

    Object.keys(lineDictionaries).forEach((taxRateId) => {
      const lines = lineDictionaries[taxRateId];
      let exTaxTotal = lines.reduce((acc, line) => acc + line.ExTaxTotalPrice, 0) * (100 - (element.GlobalDiscountValue ?? 0)) / 100;
      let inTaxTotal = lines.reduce((acc, line) => acc + line.InTaxTotalPrice, 0) * (100 - (element.GlobalDiscountValue ?? 0)) / 100;
      var taxRateObject = lines[0].TaxRateId;
      const taxAmount = inTaxTotal - exTaxTotal;

      documentFinance.push({
        ExTaxTotalAmount: exTaxTotal.toFixed(PrivateConfigurationsService.getPrecision('TotalPrecision')),
        InTaxTotalAmount: inTaxTotal.toFixed(PrivateConfigurationsService.getPrecision('TotalPrecision')),
        TaxRateId: taxRateObject,
        TaxRateName: taxRateObject.Name,
        Vat: taxAmount.toFixed(2)
      });
    });

    element.DocumentFinances = documentFinance;
    element.AmountRemaining = this.computeAmontRemaining(element);
    element = this.computeMargin(element);
    // element.AmountReceived = this.computeAmountReceived(element);
    return element;
  }

  private computeMargin(element : any) : any{
    var SaleTotalAmount = 0;
    var PurchaseTotalAmount = 0;
    element.Lines.forEach((line:any) => {
      SaleTotalAmount += line.LineType == 'product' ? line.ExTaxTotalPrice: 0;
      SaleTotalAmount += line.LineType == 'various' ? line.ExTaxTotalPrice: 0;
      PurchaseTotalAmount += line.LineType == 'product' ? (line.ExTaxPurchasePrice * line.Quantity): 0;
      PurchaseTotalAmount += line.LineType == 'various' ? (line.ExTaxPurchasePrice * line.Quantity): 0;
    });

    element.Margin = parseFloat((SaleTotalAmount-PurchaseTotalAmount).toFixed(PrivateConfigurationsService.getPrecision('TotalPrecision')));
    var temp = SaleTotalAmount>0?(((100/SaleTotalAmount) * element.Margin)):0;
    element.MarginPercent = parseFloat(temp.toFixed(PrivateConfigurationsService.getPrecision('TotalPrecision')));
    return element;
  }

  private computeAmontRemaining(element : Document) : number{
    var base = element.InTaxTotal;
    element.Payments?.forEach((p:Payment)=>{
      if(p.PaymentStateId?.Code == 'paid'){
        base = base-p.Amount;
      }
    });
    return parseFloat(base.toFixed(PrivateConfigurationsService.getPrecision('TotalPrecision')));
  }

  // private computeAmountReceived(element : Document) : number{
  //   var base = 0;
  //   element.Payments.forEach((p:Payment)=>{
  //     if(p.PaymentStateId?.Code == 'paid'){
  //       base = base + p.Amount;
  //     }
  //   });
  //   return parseFloat(base.toFixed(PrivateConfigurationsService.getPrecision('TotalPrecision')));
  // }
}

import { Injectable } from '@angular/core';
import { LoadOptions } from 'devextreme/data';
import { SaleDocumentsService } from '../../sale-documents/sale-documents/sale-documents.service';
import { SaleInvoice } from './sale-invoice.model';
import { ApiService } from '../../../core/api.service';
import { ReportsService } from '../../../connectors/reports/reports.service';
import { SaleInvoiceHeaderStatus } from '../sale-invoice-header-statuses/sale-invoice-header-status.model';
import { SaleInvoiceHeaderHeaderStatus } from '../sale-invoice-header-header-statuses/sale-invoice-header-header-status.model';
import { SaleInvoiceHeaderStatusesService } from '../sale-invoice-header-statuses/sale-invoice-header-statuses.service';
import { DocumentsService } from '../../../base/documents/documents/documents.service';
import { ThirdsService } from '../../../thirds/thirds/thirds.service';
import { DocumentDatasService } from '../../../base/documents/document-datas/document-datas.service';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { HttpResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class SaleInvoicesService extends SaleDocumentsService<SaleInvoice, SaleInvoiceHeaderStatus> {
  public override url: string = 'SaleInvoiceHeader';

  constructor(
    public override apiService: ApiService,
    public override reportsService: ReportsService,
    private saleInvoiceHeaderStatusesService: SaleInvoiceHeaderStatusesService,
    private documentsService: DocumentsService,
    private thirdsService: ThirdsService,
    public override documentDatasService: DocumentDatasService,
    public override router: Router
  ) {
    super(reportsService, documentDatasService,documentsService,router);
  }
  public override defaultOptions: LoadOptions = {
    select: [],
    expand: [
      'ThirdId.CustomerId.PaymentMethodId',
      'ThirdId.CustomerId.SaleInvoiceDocumentDelayId',
      'CurrentStatusLink.StatusId',
      'ResponsibleUserId',
      'DocumentDelayId',
      'Lines($orderby=LineNo;$expand=TaxRateId($expand=NameTranslationId),UnitId,Margin,Discount,Children,SaleDocumentLineDiscountOperations,ParentId,TaxId,AccountId, ProductId)',
      'Statuses.StatusId',
      'DocumentDataCollection.ThirdId.ContactInformations.ContactTypeId',
      'DocumentDataCollection.ThirdId.ContactInformations.AddressId',
      'DocumentDataCollection.ThirdId.Addresses.LocalityId',
      'DocumentDataCollection.ThirdId.Addresses.PostalCodeId',
      'DocumentDataCollection.AddressId.LocalityId',
      'DocumentDataCollection.AddressId.PostalCodeId',
      'DocumentDataCollection.ThirdId.CustomerId.SaleInvoiceDocumentDelayId',
      'Payments.PaymentMethodPrimary',
      'Payments.PaymentStateId',
      'Payments.PaymentTypeId',
      'ThirdId.ContactInformations.ThirdId',
      'ThirdId.ContactInformations.ContactTypeId',
      'SaleContractId',
      'DocumentRelations',
      'FirstReminder',
      'SecondReminder',
      'ThirdReminder'
    ]
  };

  public override async getInstance(invoice?: Partial<SaleInvoice>): Promise<any> {
    var statuses = await this.saleInvoiceHeaderStatusesService.load();

    var defaultStatus = new SaleInvoiceHeaderHeaderStatus({
      StatusId: statuses.find((s: any) => s.Code == 'Invoice.Proforma')
    });
    let element: Partial<SaleInvoice> = {
      Date: new Date(),
      CurrentStatusLink: defaultStatus,
      Statuses: [defaultStatus],
      Payments:[],
      DocumentRelations:[],
      ...invoice,
    };

    if (element.ThirdId && !element.ThirdId.CustomerId && element.ThirdId.Id) {
      element.ThirdId = await this.thirdsService.findByID(element.ThirdId.Id, { expand: ['CustomerId.SaleInvoiceDocumentDelayId','ContactInformations.AddressId.LocalityId','ContactInformations.AddressId.PostalCodeId'] });
    }

    if (element.ThirdId) {
      element.DocumentDataCollection = [await this.documentDatasService.getInstance(undefined, "Sale", undefined, element.ThirdId)];
    }

    if (element.ThirdId && element.ThirdId.CustomerId) {
      element.DocumentDelayId = element.ThirdId.CustomerId.SaleInvoiceDocumentDelayId;
    }

    if (element.DocumentDelayId) {
      element.Deadline = await this.documentsService.computeDeadline(element.Date ?? new Date(), element.DocumentDelayId?.Id ?? 0);
    }
    // element = await this.refreshResponsibleUser(element);
    // element = await this.refreshThirdDocumentDelayFromCustomer(element);

    return element;
  }

  public getReminder(invoice : SaleInvoice, reminderNumber : number){
    let reminder = invoice.getReminderFromNumber(reminderNumber);
    let request : Observable<File>;
    if(reminder != null){
      request = this.apiService.downloadFile(`/api/SaleInvoiceReporting/GetReminder/${invoice.Id}?reminderNumber=${reminderNumber}`);
    }else{
      request = this.apiService.downloadFile(`/api/SaleInvoiceReporting/GenerateReminder/${invoice.Id}?reminderNumber=${reminderNumber}`);
    }
    return request;
  }

  public regenerateReminder(invoice : SaleInvoice, reminderNumber : number){
    let request : Observable<File> = this.apiService.downloadFile(`/api/SaleInvoiceReporting/ForceGenerateReminder/${invoice.Id}?reminderNumber=${reminderNumber}`);
    return request;
  }

  public async getStatistics(filter: any) {
    var filters = this.convertODataFiltersToString(filter);
    if (filters == "") return undefined;
    return this.apiService.sendRequest('/api/SaleInvoice/getStatistiques/' + filters, 'GET');
  }
}

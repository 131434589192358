import { Component } from '@angular/core';
import { SaleCreditNote } from '../sale-credit-notes/sale-credit-note.model';
import { SaleCreditNotesService } from '../sale-credit-notes/sale-credit-notes.service';
import { SaleCreditNoteLine } from '../sale-credit-note-lines/sale-credit-note-line.model';
import { SaleCreditNoteLinesService } from '../sale-credit-note-lines/sale-credit-note-lines.service';
import { SaleCreditNoteHeaderStatus } from '../sale-credit-note-header-statuses/sale-credit-note-header-status.model';
import { SaleCreditNoteHeaderStatusesService } from '../sale-credit-note-header-statuses/sale-credit-note-header-statuses.service';
import { SaleCreditNoteHeaderHeaderStatus } from '../sale-credit-note-header-header-statuses/sale-credit-note-header-header-status.model';
import { SaleCreditNoteHeaderHeaderStatusesService } from '../sale-credit-note-header-header-statuses/sale-credit-note-header-header-statuses.service';
import { SaleDocumentDetailsComponent } from '../../sale-documents/sale-documents/sale-document-details/sale-document-details.component';
import { AppInjectorService } from 'projects/libraries/syslink-components/src/lib/services/app-injector.service';
import { SyslinkColumn } from 'projects/libraries/syslink-components/src/lib/helpers/SyslinkColumn';
import { TaxesService } from '../../../accountings/taxes/taxes.service';
import { AccountsService } from '../../../accountings/accounts/accounts.service';
import { TranslateService } from '@ngx-translate/core';
import { MailModel } from '../../../connectors/mail/mail.model';
import { DocumentData } from '../../../base/documents/document-datas/document-data.model';
import { ThirdContactInformation } from '../../../thirds/thirds/third-contacts/third-contact-informations/third-contact-information.model';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ActivatedRoute } from '@angular/router';
import { ReportsService } from '../../../connectors/reports/reports.service';
import { MailTemplateTypesService } from '../../../mails/mail-template-types/mail-template-types.service';
import { DocumentsService } from '../../../base/documents/documents/documents.service';
import { ThirdsService } from '../../../thirds/thirds/thirds.service';
import { DocumentDatasService } from '../../../base/documents/document-datas/document-datas.service';
import { PaymentsService } from '../../../base/payments/payments.service';
import { DocumentRelationsService } from '../../../base/documents/document-relations/document-relation.service';
import { SaleDocumentLineDiscountOperationsService } from '../../sale-documents/sale-document-line-discount-operations/sale-document-line-discount-operations.service';
import { ModificationService } from '../../../core/services/modification.service';
import { SaleInvoiceHeaderStatusesService } from '../../sale-invoices/sale-invoice-header-statuses/sale-invoice-header-statuses.service';
import { SaleOrderHeaderStatusesService } from '../../sale-orders/sale-order-header-statuses/sale-order-header-statuses.service';
import { SaleQuoteHeaderStatusesService } from '../../sale-quotes/sale-quote-header-statuses/sale-quote-header-statuses.service';
import { SaleContractHeaderStatusesService } from '../../sale-contracts/sale-contract-header-statuses/sale-contract-header-statuses.service';

@Component({
  selector: 'app-sale-credit-note-details',
  templateUrl: '../../sale-documents/sale-documents/sale-document-details/sale-document-details.component.html',
  styleUrls: ['./sale-credit-note-details.component.scss']
})
export class SaleCreditNoteDetailsComponent extends SaleDocumentDetailsComponent<
  SaleCreditNote,
  SaleCreditNotesService,
  SaleCreditNoteLine,
  SaleCreditNoteLinesService,
  SaleCreditNoteHeaderStatus,
  SaleCreditNoteHeaderStatusesService,
  SaleCreditNoteHeaderHeaderStatus,
  SaleCreditNoteHeaderHeaderStatusesService
>{

  
  constructor(
    public override saleQuoteHeaderStatusesService: SaleQuoteHeaderStatusesService,
    public override saleOrderHeaderStatusesService:SaleOrderHeaderStatusesService,
    public override saleInvoiceHeaderStatusesService:SaleInvoiceHeaderStatusesService,
    public override saleCreditNoteHeaderStatusesService:SaleCreditNoteHeaderStatusesService,
    public override saleContractHeaderStatusesService:SaleContractHeaderStatusesService,
    public override ngxUiLoaderService: NgxUiLoaderService,
    public override activatedRoute: ActivatedRoute,
    public override reportsService: ReportsService,
    private mailTemplateTypesService: MailTemplateTypesService,
    public override documentService: DocumentsService,
    public override thirdsService: ThirdsService,
    public override documentDatasService: DocumentDatasService,
    public override paymentsService: PaymentsService,
    public override documentRelationsService: DocumentRelationsService,
    public override saleDocumentLineDiscountOperationsService:SaleDocumentLineDiscountOperationsService,
    public override modificationService : ModificationService
  ) {
    super(saleQuoteHeaderStatusesService,saleOrderHeaderStatusesService,saleInvoiceHeaderStatusesService,saleCreditNoteHeaderStatusesService,saleContractHeaderStatusesService,ngxUiLoaderService, activatedRoute, reportsService, documentDatasService, thirdsService,paymentsService,documentRelationsService,saleDocumentLineDiscountOperationsService, documentService,modificationService);
  }
  
  protected override loadDependencies() {
    this.saleDocumentService = AppInjectorService.injector.get(SaleCreditNotesService);
    this.saleDocumentLinesService = AppInjectorService.injector.get(SaleCreditNoteLinesService);
    this.saleDocumentStatusesService = AppInjectorService.injector.get(SaleCreditNoteHeaderStatusesService);
    this.saleDocumentHeaderStatusesService = AppInjectorService.injector.get(SaleCreditNoteHeaderHeaderStatusesService);
    this.saleDocumentLinesService.documentType = 'SaleCreditNote';

    const taxesService = AppInjectorService.injector.get(TaxesService);
    const accountsService = AppInjectorService.injector.get(AccountsService);
    const translateService = AppInjectorService.injector.get(TranslateService);

    this.documentType = 'SaleCreditNote';
    this.subModuleCode = 'Sales.CreditNote';
    this.defaultStatusCode = 'CreditNote.Proforma';
    this.documentLinesColumns = [...this.saleDocumentService.getDefaultDocumentLineColumns(this.subModuleCode,this.documentType),
    // new SyslinkColumn({ order: 15, field: 'TaxId', label: translateService.instant('Tax'), type: 'string', cellTemplate: 'select-value', editCellTemplate: 'select-cell', data: { service: taxesService, displayKey: 'Alias', filter:"IsVisible eq true"  }, width: 70 }),
    // new SyslinkColumn({ order: 15, field: 'AccountId', label: translateService.instant('Account'), type: 'string', cellTemplate: 'select-value', editCellTemplate: 'select-cell', data: { service: accountsService, displayKey: 'Name' }, width: 70 }),
    ];

  }

  public override canEditDocument(): boolean {
    return !this.hasActiveStatus('CreditNote.Ventilated') && !this.hasActiveStatus('CreditNote.Canceled');
  }

    // Mail
  // ----
  public override async onSendMailButtonClicked() {
    this.mailModel = undefined;
    setTimeout(async () => {
      this.mailModel = new MailModel();

      this.mailTemplateType = (await this.mailTemplateTypesService.load({ filter: ["Code eq 'SaleCreditNote'"] }))[0];

      this.element.DocumentDataCollection.forEach((d: DocumentData) => {
        if ((d.HasPrincipal || d.HasSecondary) && this.mailModel) {
          this.mailModel.ToThirdContactInformationId.push(...d.ThirdId.ContactInformations.filter((c: ThirdContactInformation) => c.ContactTypeId?.Code == 'email' && c.UsedForInvoice == true));
        }
        if (d.HasCC && this.mailModel) {
          this.mailModel.CCThirdContactInformationId.push(...d.ThirdId.ContactInformations.filter((c: ThirdContactInformation) => c.ContactTypeId?.Code == 'email' && c.UsedForInvoice == true));
        }
        if (d.HasCCI && this.mailModel) {
          this.mailModel.CCIThirdContactInformationId.push(...d.ThirdId.ContactInformations.filter((c: ThirdContactInformation) => c.ContactTypeId?.Code == 'email' && c.UsedForInvoice == true));
        }
      })

      this.mailModel.ReportType = this.documentType;
      this.mailModel.HeaderId = this.element.Id?.toString() ?? "0";
      this.mailModel.FileName = this.reportsService.updateFileName(this.documentType, this.element.No + ".pdf");
      if (!this.emailModal) return;
      this.emailModal.mailTemplateType = this.mailTemplateType;
      await this.emailModal.loadMailTemplateData();

      await this.emailModal?.open();
    }, 50);
  }
  //-------------------------------------------------------------------------
}
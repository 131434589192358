import { Component, OnInit, ViewChild } from '@angular/core';
import { ConfirmModalComponent, ModalComponent, NotificationsService, PageComponent, SyslinkToolbarActionButton } from 'projects/libraries/syslink-components/src/public-api';
import { TaskActionCode, TasksService } from './tasks.service';
import { ActivatedRoute } from '@angular/router';
import { Task } from './task.model';
import { TaskModalComponent } from './task-modal/task-modal.component';
import { TaskKanbanComponent } from './task-kanban/task-kanban.component';
import { TaskGridComponent } from './task-grid/task-grid.component';
import { TaskMultipleEditModalComponent } from './task-multiple-edit-modal/task-multiple-edit-modal.component';
import { ContextMenuItemAction } from 'projects/libraries/syslink-components/src/lib/context-menus/context-menu-item-action';
import { ContextMenuItemClickEvent } from 'devextreme/ui/file_manager';
import { WorkTimeModalComponent } from '../../work-times/work-times/work-time-modal/work-time-modal.component';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { WorkTime } from '../../work-times/work-times/work-time';
// import { AppointmentModalComponent } from '../../appointments/appointments/appointment-modal/appointment-modal.component';
// import { AppointmentsService } from '../../appointments/appointments/appointments.service';
// import { Appointment } from '../../appointments/appointments/appointment';
import { WorkTimesService } from '../../work-times/work-times/work-times.service';
import { ReportsService } from '../../../connectors/reports/reports.service';
import { SaleInvoiceSelectModalComponent } from '../../../sales/sale-invoices/sale-invoice-select-modal/sale-invoice-select-modal.component';
import { SaleInvoice } from '../../../sales/sale-invoices/sale-invoices/sale-invoice.model';
import { UserTagboxModalComponent } from '../../../core/auth/users/user-tagbox-modal/user-tagbox-modal.component';
import { User } from '../../../core/auth/users/user.model';
import { UserGroup } from '../../../core/auth/user-groups/user-group.model';
import { DynamicPriceInvoicingComponent } from '../dynamic-price-invoicing/dynamic-price-invoicing.component';
import { UserFormComponent } from '../../../core/auth/users/user-form/user-form.component';
import { TaskTaskStatusModalComponent } from '../task-task-statuses/task-task-status-modal/task-task-status-modal.component';
import { TaskStatus } from '../task-statuses/task-status.model';
import { TaskTaskStatus } from '../task-task-statuses/task-task-status.model';
import { TaskTaskStatusesService } from '../task-task-statuses/task-task-statuses.service';
import { TaskBillingTypeCode } from '../task-billing-types/task-billing-type.model';
import { TaskParticipant } from '../task-participants/task-participant.model';
import { TaskParticipantsService } from '../task-participants/task-participants.service';
import { UserGroupsService } from '../../../core/auth/user-groups/user-groups.service';
import { UsersService } from '../../../core/auth/users/users.service';
import { AppInjectorService } from 'projects/libraries/syslink-components/src/lib/services/app-injector.service';


@Component({
  selector: 'app-tasks',
  templateUrl: './tasks.component.html',
  styleUrls: ['./tasks.component.scss']
})
export class TasksComponent extends PageComponent implements OnInit {

  // View Type
  // ---------
  // private defaultViewType: TaskViewType = TasksViewTypeCode.Grid;
  // public viewType: TaskViewType = this.defaultViewType;
  // public viewTypeStorageKey: string = "tasks-viewType";

  // Grid
  // ----
  @ViewChild('taskGrid') taskGrid?: TaskGridComponent;
  @ViewChild('dynamicPriceInvoicing') dynamicPriceInvoicing?: DynamicPriceInvoicingComponent;
  // public kanbanSwitchToolbarButton = new SyslinkToolbarActionButton({
  //   icon: 'mediumiconslayout',
  //   onClick: () => this.changeViewType(TasksViewTypeCode.Kanban),
  //   location: 'after'
  // });

  public taskGridContextMenuItems: ContextMenuItemAction[] = [
    // {
    //   text: "Scheduling", icon: "event", items: [
    //     { code: TaskActionCode.Schedule, text: "Schedule it", onItemClick: (e: any) => this.onMenuItemClicked(e), visible: this.authService.hasPermission('time-management.tasks.contextMenu.workIt') },
    //     { code: TaskActionCode.MarkAsNotSchedulable, text: "Mark as not schedulable", onItemClick: (e: any) => this.onMenuItemClicked(e), visible: this.authService.hasPermission('time-management.tasks.contextMenu.updateWorkableStatus') },
    //     { code: TaskActionCode.MarkAsSchedulable, text: "Mark as schedulable", onItemClick: (e: any) => this.onMenuItemClicked(e), visible: this.authService.hasPermission('time-management.tasks.contextMenu.updateWorkableStatus') },
    //   ], visible: this.authService.hasPermission('time-management.tasks.contextMenu.workIt') ?? this.authService.hasPermission('time-management.tasks.contextMenu.updateWorkableStatus') ?? this.authService.hasPermission('time-management.tasks.contextMenu.updateWorkableStatus')
    // },
    {
      text: "Work times", icon: "clock", items: [
        //     { code: TaskActionCode.Work, text: "Work it", onItemClick: (e: any) => this.onMenuItemClicked(e), visible: this.authService.hasPermission('time-management.tasks.contextMenu.schedulIt') },
        //     { code: TaskActionCode.MarkAsNotWorkable, text: "Mark as not workable", onItemClick: (e: any) => this.onMenuItemClicked(e), visible: this.authService.hasPermission('time-management.tasks.contextMenu.updateschedulingStatus') },
        //     { code: TaskActionCode.MarkAsWorkable, text: "Mark as workable", onItemClick: (e: any) => this.onMenuItemClicked(e), visible: this.authService.hasPermission('time-management.tasks.contextMenu.updateschedulingStatus') },
        { code: TaskActionCode.TimesheetsByGroup, text: "Timesheets", icon: "event", onItemClick: (e: any) => this.onMenuItemClicked(e), visible: this.authService.hasPermission('time-management.tasks.contextMenu.previewWorkTime') },
      ], visible:
        // this.authService.hasPermission('time-management-work-times') && (this.authService.hasPermission('time-management.tasks.contextMenu.schedulIt') ?? this.authService.hasPermission('time-management.tasks.contextMenu.updateschedulingStatus') ?? 
        this.authService.hasPermission('time-management.tasks.contextMenu.previewWorkTime')
      // )
    },
    {
      code: TaskActionCode.Invoice, text: "Invoice it", onItemClick: (e: any) => this.onMenuItemClicked(e), visible: this.authService.hasPermission('time-management.tasks.contextMenu.invoiceIt'),
    },

    // {
    //   text: "Invoicing", icon: "money", items: [
    //     { code: TaskActionCode.Invoice, text: "Invoice it", onItemClick: (e: any) => this.onMenuItemClicked(e), visible: this.authService.hasPermission('time-management.tasks.contextMenu.invoiceIt') },
    //     // { code: TaskActionCode.MarkAsNotBillable, text: "Mark as not billable", onItemClick: (e: any) => this.onMenuItemClicked(e), visible: this.authService.hasPermission('time-management.tasks.contextMenu.updateInvoicingStatus') },
    //     // { code: TaskActionCode.MarkAsBillable, text: "Mark as billable", onItemClick: (e: any) => this.onMenuItemClicked(e), visible: this.authService.hasPermission('time-management.tasks.contextMenu.updateInvoicingStatus') },
    //   ], visible: this.authService.hasPermission('time-management.tasks.contextMenu.invoiceIt') ?? this.authService.hasPermission('time-management.tasks.contextMenu.updateInvoicingStatus')
    // },
    {
      text: "Assign", icon: "user", items: [
        { code: TaskActionCode.AssignToMe, text: "Me", onItemClick: (e: any) => this.onMenuItemClicked(e), visible: this.authService.hasPermission('time-management.tasks.contextMenu.assignToMe') },
        { code: TaskActionCode.AssignToParticipants, text: "Users", onItemClick: (e: any) => this.onMenuItemClicked(e), visible: this.authService.hasPermission('time-management.tasks.contextMenu.assignToUser') },
      ], visible: this.authService.hasPermission('time-management.tasks.contextMenu.assignToMe') ?? this.authService.hasPermission('time-management.tasks.contextMenu.assignToUser')
    },
    { code: TaskActionCode.ChangeStatus, text: "Change status", icon: "edit", onItemClick: (e: any) => this.onMenuItemClicked(e), visible: this.authService.hasPermission('time-management.tasks.contextMenu.changeStatus') },
    { code: TaskActionCode.Edit, beginGroup: true, text: "Edit", icon: "edit", onItemClick: (e: any) => this.onMenuItemClicked(e), visible: this.authService.hasPermission('time-management.tasks.contextMenu.update') },
    { code: TaskActionCode.Delete, text: "Delete", icon: "trash", onItemClick: (e: any) => this.onMenuItemClicked(e), visible: this.authService.hasPermission('time-management.tasks.contextMenu.delete') }
  ];

  // ----------------------------

  // Kanban
  // ------
  // @ViewChild('taskKanban') taskKanban?: TaskKanbanComponent;
  // public gridSwitchToolbarButton = new SyslinkToolbarActionButton({
  //   icon: 'detailslayout',
  //   onClick: () => this.changeViewType(TasksViewTypeCode.Grid),
  //   location: 'after'
  // });
  // ----------------------------

  // Modal
  // -----
  @ViewChild("taskModal") taskModal?: TaskModalComponent;
  @ViewChild("taskMultipleEditModal") taskMultipleEditModal?: TaskMultipleEditModalComponent;
  @ViewChild("worktimeModal") worktimeModal?: WorkTimeModalComponent;
  // @ViewChild("appointmentModal") appointmentModal?: AppointmentModalComponent;
  @ViewChild("confirmModal") confirmModal?: ConfirmModalComponent;
  @ViewChild('saleInvoiceSelectModal') saleInvoiceSelectModal?: SaleInvoiceSelectModalComponent;
  @ViewChild('participantTagboxModal') participantTagboxModal?: ModalComponent;
  // @ViewChild('userGroupTagboxModal') userGroupTagboxModal?: UserTagboxModalComponent;
  @ViewChild("statusModal") statusModal?: TaskTaskStatusModalComponent;
  // ----------------------------

  // Filter
  // ------
  // public saleInvoiceSelectModalFilter: string | string[] = ["CurrentStatusLink.StatusId.Sequence lt 3"];
  // ----------------------------
  public userAndUserGroupItems?: any;

  constructor(
    public tasksService: TasksService,
    public override activatedRoute: ActivatedRoute,
    private ngxUiLoaderService: NgxUiLoaderService,
    // private appointmentsService: AppointmentsService,
    private workTimesService: WorkTimesService,
    private reportsService: ReportsService,
    public taskTaskStatusesService: TaskTaskStatusesService,
    public userGroupsService: UserGroupsService,
    public override usersService: UsersService,
    private taskParticipantsService: TaskParticipantsService

  ) {
    super();
    // this.inithViewType();
  }

  override async ngOnInit(): Promise<void> {
    await this.loadUserAndUserGroup();
  }

  private async loadUserAndUserGroup() {
    this.userAndUserGroupItems = [];
    var users = await this.usersService.load({ filter: ["IsActive eq true"], select: ['Oid', 'Name', 'UserName'], expand: [] });
    var userGroups = await this.userGroupsService.load({ filter: ["IsActive eq true"], expand: ['NameTranslationId.Translations.LanguageId'], select: ['Id', 'Name'] });

    users.forEach((u: User) => {
      this.userAndUserGroupItems?.push({ Id: this.userAndUserGroupItems.length + 1, 'FormattedName': u.Name, user: u, isUser: true });
    })
    userGroups.forEach((u: UserGroup) => {
      this.userAndUserGroupItems?.push({ Id: this.userAndUserGroupItems.length + 1, 'FormattedName': u.Name, user: u, isUser: false });
    })
  }

  // Grid
  // ----
  public async onAddButtonClicked() {
    if (!this.taskModal) return;

    this.taskModal.title = "Create new Task";
    this.taskModal.open(await this.tasksService.getInstance());
  };

  public async ondRowDoubleClicked(e: Task) {
    if (!this.taskModal) return;

    this.taskModal.title = "Edit task";
    let data: any = await this.tasksService.getInstance({
      ...e,
      EndDate: e?.EndDate?.getFullYear() !== undefined && e.EndDate.getFullYear() > 1 ? e.EndDate : undefined,

    });
    data.StatusId = e?.CurrentStatusLink?.StatusId;
    this.taskModal?.open(data);
  };

  public async onValidateModalButtonClicked(element: any) {
    let newStatus = null;
    if (element.CurrentStatusLink?.StatusId.Id != element.StatusId?.Id) {
      newStatus = element.StatusId;
    }
    delete element.StatusId;

    await this.tasksService.createOrUpdate(element);

    if (newStatus != null) {
      const link = await this.taskTaskStatusesService.getInstance({
        TaskId: element,
        StatusId: { Id: newStatus.Id },
        Date: new Date()
      })
      var documentStatus = await this.taskTaskStatusesService.insert(this.taskTaskStatusesService.format(link));
    }
    AppInjectorService.config.setModificationGuard(false);
    this.taskModal?.modal?.close();
    this.taskGrid?.refresh();
  }

  public async onCancelModal() {
    AppInjectorService.config.setModificationGuard(false);
  }
  // ------------------------------------------------------------------------------------------------

  // View Type
  // ---------
  // private inithViewType() {
  //   const storedViewType = localStorage.getItem(this.viewTypeStorageKey) ?? this.defaultViewType;
  //   this.viewType = this.isViewTypeAllowed(storedViewType) ? <TaskViewType>storedViewType : this.defaultViewType;
  // }

  // private changeViewType(viewType: TaskViewType) {
  //   this.viewType = viewType;
  //   localStorage.setItem(this.viewTypeStorageKey, viewType);
  // }

  // private isViewTypeAllowed(viewType: string) {
  //   return (<string[]>Object.values(TasksViewTypeCode)).includes(viewType)
  // }
  // ------------------------------------------------------------------------------------------------

  // ContextMenu
  // -----------
  public async onMenuItemClicked(e: ContextMenuItemClickEvent) {

    if (!this.taskGrid) return;

    switch (e.itemData.code) {
      // Invoicing
      // ---------
      case TaskActionCode.Invoice:
        if (!this.tasksService.can(TaskActionCode.Invoice,(Array.isArray(e.itemData.rowData)? e.itemData.rowData:[e.itemData.rowData]))) return;
        this.showInvoiceModal("Would you like to invoice selected rows?", e.itemData);
        break;
      // case TaskActionCode.MarkAsNotBillable:
      //   this.showConfirmModal("Would you like to make all selected rows not billable?", e.itemData)
      //   break;
      // case TaskActionCode.MarkAsBillable:
      //   this.showConfirmModal("Would you like to make all selected rows billable?", e.itemData)
      //   break;

      // Work times
      // ----------
      case TaskActionCode.Work:
        await this.workTimeTasks(this.taskGrid.getSelectedRows(e.itemData.rowData));
        break;
      case TaskActionCode.MarkAsNotWorkable:
        this.showConfirmModal("Would you like to make all selected rows not workable?", e.itemData)
        break;
      case TaskActionCode.MarkAsWorkable:
        this.showConfirmModal("Would you like to make all selected rows workable?", e.itemData)
        break;

      // Scheduling
      // ----------
      // case TaskActionCode.Schedule:
      //   await this.scheduleTasks(this.taskGrid.getSelectedRows(e.itemData.rowData));
      //   break;
      // case TaskActionCode.MarkAsNotSchedulable:
      //   this.showConfirmModal("Would you like to make all selected rows not schedulable?", e.itemData)
      //   break;
      // case TaskActionCode.MarkAsSchedulable:
      //   this.showConfirmModal("Would you like to make all selected rows schedulable?", e.itemData)
      //   break;

      // Timesheets
      // ----------
      case TaskActionCode.TimesheetsByGroup:
        this.showTimesheets(this.taskGrid.getSelectedRows(e.itemData.rowData), TaskActionCode.TimesheetsByGroup);
        break;
      // case TaskActionCode.TimesheetsByList:
      //   this.showTimesheets(this.taskGrid.getSelectedRows(e.itemData.rowData), TaskActionCode.TimesheetsByList);
      //   break;

      // Users
      // ------
      case TaskActionCode.AssignToMe:
        this.showConfirmModal("Would you like add user?", e.itemData)
        break;
      case TaskActionCode.AssignToParticipants:
        this.showUserTagboxModal("Would you like update users?", e.itemData);
        break;

      // Others
      // ------
      case TaskActionCode.ChangeStatus:
        this.showStatusModal("Would you like change status selected rows?", e.itemData);
        break;
      case TaskActionCode.Edit:
        this.editMultipleTasks(this.taskGrid.getSelectedRows(e.itemData.rowData));
        break;
      case TaskActionCode.Delete:
        this.showConfirmModal("Would you like to delete all selected rows?", e.itemData)
        break;
    }
  }

  private showStatusModal(text: string, data: ContextMenuItemAction) {
    if (!this.statusModal) return;

    this.statusModal.title = data.text ? data.text : "Confirm";
    this.statusModal.content = text;
    this.statusModal.open({ rows: this.taskGrid?.getSelectedRows(data.rowData), action: data.code });
  }

  private showConfirmModal(text: string, data: ContextMenuItemAction) {
    if (!this.confirmModal) return;

    this.confirmModal.title = data.text ? data.text : "Confirm";
    this.confirmModal.content = text;
    this.confirmModal.open({ rows: this.taskGrid?.getSelectedRows(data.rowData), action: data.code });

    this.ngxUiLoaderService.stop();
  }

  private showInvoiceModal(text: string, data: ContextMenuItemAction) {
    if (!this.saleInvoiceSelectModal) return;

    this.saleInvoiceSelectModal.title = data.text ? data.text : "Confirm";
    this.saleInvoiceSelectModal.content = text;
    this.saleInvoiceSelectModal.open({ rows: this.taskGrid?.getSelectedRows(data.rowData), action: data.code, thirdId: data.rowData.ThirdId.Id });
    this.saleInvoiceSelectModal.updateFilter();
    this.ngxUiLoaderService.stop();
  }
  private showUserTagboxModal(text: string, data: ContextMenuItemAction) {
    if (!this.participantTagboxModal) return;
    this.participantTagboxModal.data.tagBoxTaskParticipants = [];
    this.participantTagboxModal.open({ rows: this.taskGrid?.getSelectedRows(data.rowData), action: data.code, AssignmentType: true, tagBoxTaskParticipants: [] });
  }
  // private showUserGroupTagboxModal(text: string, data: ContextMenuItemAction) {
  //   if (!this.userGroupTagboxModal) return;

  //   this.userGroupTagboxModal.title = data.text ? data.text : "Confirm";
  //   this.userGroupTagboxModal.content = text;
  //   this.userGroupTagboxModal.open({ rows: this.taskGrid?.getSelectedRows(data.rowData), action: data.code, AssignmentType: true });
  //   this.userGroupTagboxModal.reload();

  //   this.ngxUiLoaderService.stop();
  // }


  public async onConfirmModalValidated(modalData: { rows: Task[], action: string, tagBoxTaskParticipants: any[] } | any) {
    switch (modalData.action) {
      // Invoicing
      // ---------
      case TaskActionCode.Invoice:
        await this.invoiceTasks(modalData.rows, modalData.Invoice);
        break;
      // case TaskActionCode.MarkAsBillable:
      //   await this.tasksService.tryUpdateMultiples(modalData.rows, [{ field: 'IsBillable', value: true }]);
      //   break;
      // case TaskActionCode.MarkAsNotBillable:
      //   await this.tasksService.tryUpdateMultiples(modalData.rows, [{ field: 'IsBillable', value: false }]);
      //   break;

      // Scheduling
      // ----------
      // case TaskActionCode.MarkAsSchedulable:
      //   await this.tasksService.tryUpdateMultiples(modalData.rows, [{ field: 'IsPlannable', value: true }]);
      //   break;
      // case TaskActionCode.MarkAsNotSchedulable:
      //   await this.tasksService.tryUpdateMultiples(modalData.rows, [{ field: 'IsPlannable', value: false }]);
      //   break;

      // Work times
      // ----------
      case TaskActionCode.MarkAsWorkable:
        await this.tasksService.tryUpdateMultiples(modalData.rows, [{ field: 'IsPerformable', value: true }]);
        break;
      case TaskActionCode.MarkAsNotWorkable:
        await this.tasksService.tryUpdateMultiples(modalData.rows, [{ field: 'IsPerformable', value: false }]);
        break;

      // Assignement
      // -----------
      case TaskActionCode.AssignToMe:
        await this.assignTasksToMe(modalData.rows);
        break;
      case TaskActionCode.AssignToParticipants:
        await this.AssignTasksToParticipants(modalData.rows, modalData.tagBoxTaskParticipants);
        break;

      // Others
      // ------
      case TaskActionCode.ChangeStatus:
        await this.updateStatus(modalData.rows, modalData.TaskStatus);
        break;
      case TaskActionCode.Delete:
        await this.deleteTasks(modalData.rows);
        break;
    }

    this.taskGrid?.refresh();
    this.saleInvoiceSelectModal?.close();
    this.participantTagboxModal?.close();
    this.confirmModal?.close();
    this.statusModal?.close();
    //this.taskKanban?.refresh();
  }
  // ------------------------------------------------------------------------------------------------

  // Task Actions
  // ------------
  public async editMultipleTasks(selectedRows: Task[]) {
    if (selectedRows.length == 1) {
      this.editSingleTask(selectedRows[0]);
      return;
    }

    if (!this.taskMultipleEditModal) return;
    this.taskMultipleEditModal?.open();
    this.taskMultipleEditModal.tasks = selectedRows;
    this.taskMultipleEditModal?.loadData();
  }

  public async editSingleTask(task: Task) {
    if (!this.taskGrid?.detailsUrl) return;
    this.ngxUiLoaderService.start();
    this.goToUrl(this.taskGrid?.detailsUrl + task.Id);
  }

  public onValidateModalMultipleEdit() {
    this.taskGrid?.refresh();
  }

  public async deleteTasks(selectedRows: Task[]) {
    for (const row of selectedRows) {
      if (row.Id)
        await this.tasksService.remove(row.Id)
    }
  }

  // Schedule
  // --------
  // private async scheduleTasks(selectedRows: Task[]) {
  //   if (!this.tasksService.validateFieldValueAllowed(selectedRows, [{ field: TaskActionCode.Schedule, value: true }])) return;
  //   if (!this.tasksService.can(TaskActionCode.Schedule, selectedRows)) return;

  //   this.appointmentModal?.open(await this.appointmentsService.getInstance({
  //     ThirdId: selectedRows[0].ThirdId,
  //     TaskId: selectedRows[0],
  //   }));
  // }

  // public async onValidateAppointmentCreation(e: Appointment) {
  //   await this.appointmentsService.insert(this.appointmentsService.format(e));
  //   NotificationsService.sendSuccess("Record created");
  //   this.appointmentModal?.close();
  //   this.taskGrid?.refresh();
  // }

  // Work
  // ----
  private async workTimeTasks(selectedRows: Task[]) {
    if (!this.tasksService.validateFieldValueAllowed(selectedRows, [{ field: TaskActionCode.Work, value: true }])) return;
    if (!this.tasksService.can(TaskActionCode.Work, selectedRows)) return;

    this.worktimeModal?.open(await this.workTimesService.getInstance({
      ThirdId: selectedRows[0].ThirdId,
      TaskId: selectedRows[0],
      IsBillable: selectedRows[0].TaskBillingTypeId.Code != TaskBillingTypeCode.NotBillable ? true : false
    }));
  }

  public async onValidateWorkTimeCreation(e: WorkTime) {
    await this.workTimesService.insert(this.workTimesService.format(e));
    NotificationsService.sendSuccess("Record created");
    this.worktimeModal?.close();
    this.taskGrid?.refresh();
  }

  // Invoice
  // -------
  private async invoiceTasks(selectedRows: Task[], invoice: SaleInvoice | undefined) {
    if (!this.tasksService.can(TaskActionCode.Invoice, selectedRows)) return;

    let dynamicPriceTasks = selectedRows.filter(task => task.TaskBillingTypeId.Code != TaskBillingTypeCode.FixedPrice);

    if (dynamicPriceTasks.length > 0) {
      if (this.dynamicPriceInvoicing === undefined) throw new Error("Error with dynamic price invoicing component.");
      // NotificationsService.sendInfo("Some tasks are not fixed-price, please select the work time you want to add to the invoice.");
      this.dynamicPriceInvoicing.tasks = dynamicPriceTasks;
      this.dynamicPriceInvoicing.tasksToInvoice = selectedRows.filter(task => task.TaskBillingTypeId.Code == TaskBillingTypeCode.FixedPrice);
      this.dynamicPriceInvoicing.invoiceId = invoice?.Id;
      this.dynamicPriceInvoicing.onAddWorkTimes();
      // Let the dynamic price invoicing component handle the communication with the backend.
    } else {
      await this.tasksService.invoice({
        taskIds: selectedRows.map(e => e.Id!),
        invoiceId: invoice?.Id
      });
      NotificationsService.sendSuccess('Invoice created');
    }
  }

  // Assign
  // ------
  private async assignTasksToMe(selectedRows: Task[]) {
    selectedRows = selectedRows.filter(row => {
      return !(row.TaskParticipants.find((u: TaskParticipant) => u.IsUser && u.UserId && u.UserId.Oid?.toString() == this.authService.user?.Oid?.toString()))
    });

    if (selectedRows.length == 0) {
      NotificationsService.sendErrorMessage("No task updated");
      return;
    }

    for (const task of selectedRows) {
      if (this.authService.user == undefined || !task.Id) return;
      await this.taskParticipantsService.insert(this.taskParticipantsService.format(new TaskParticipant({
        IsUser: true,
        BackgroundColor: this.authService.user.BackgroundColor,
        ForegroundColor: this.authService.user.ForegroundColor,
        UserId: this.authService.user,
        UserGroupId: undefined,
        TaskId: task
      })));
    };
  }
  private async AssignTasksToParticipants(selectedRows: Task[], tagBoxTaskParticipants: any[]) {
    for (const participant of tagBoxTaskParticipants) {
      selectedRows = selectedRows.filter(row => {
        return !(row.TaskParticipants.find((e: TaskParticipant) => {
          if (e.IsUser) {
            // Si c'est un utilisateur, comparer les identifiants Oid
            return participant.user.Oid.toString() === e.UserId?.Oid?.toString();
          } else {
            // Si c'est un groupe, comparer les identifiants Id
            return participant.user.Id === e.UserGroupId?.Id;
          }
        }))
      })

      for (const task of selectedRows) {
        if (!task.Id) return;
        await this.taskParticipantsService.insert(this.taskParticipantsService.format(new TaskParticipant({
          IsUser: participant.isUser,
          BackgroundColor: participant.user.BackgroundColor,
          ForegroundColor: participant.user.ForegroundColor,
          UserId: participant.isUser ? participant.user : undefined,
          UserGroupId: !participant.isUser ? participant.user : undefined,
          TaskId: task
        })));
      };
    }

  }


  // Timesheets
  // ----------
  public showTimesheets(selectedRows: Task[], taskActionCode: TaskActionCode) {
    if (selectedRows[0].Id)
      this.reportsService.previewWorkTimes(selectedRows.map(e => e.Id!), 'Task', 'Worktime', taskActionCode);
  }

  // Status
  // ------
  private async updateStatus(selectedRows: Task[], status: TaskStatus) {
    if (!this.tasksService.canUpdateStatus(selectedRows, status)) return;

    for (var element of selectedRows) {
      if (element.Id) {
        const link: TaskTaskStatus = {
          TaskId: element,
          StatusId: { Id: status.Id },
          Date: new Date()
        };
        await this.taskTaskStatusesService.insert(this.taskTaskStatusesService.format(link));
      }
    }
    NotificationsService.sendSuccess('Status updated');
  }
}


// export enum TasksViewTypeCode {
//   Kanban = 'kanban',
//   Grid = 'grid'
// }

// export type TaskViewType = TasksViewTypeCode.Grid | TasksViewTypeCode.Kanban;
